import { FilterCustomFieldValuesService } from './../services/filter-custom-field-values.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertsService } from './../services/alerts.service';
import { DataHolderService } from 'src/app/shared/services/data-holder.service';

@Component({
  selector: 'app-filter-custom-field-values',
  templateUrl: './filter-custom-field-values.component.html',
  styleUrls: ['./filter-custom-field-values.component.scss']
})
export class FilterCustomFieldValuesComponent implements OnInit {


  Object = Object;

  @Input() type_of_data: string | number | any;

  @Input() account_filter_type: string | number;


  public validate = false;

  public loading = false;

  preselectedClientValue: any;

  hasPreSelectedClientValue: any = false;

  preselectedVendorValue: any;

  hasPreSelectedVendorValue: any = false;

  preselectedProjectValue: any;

  hasPreSelectedProjectValue: any = false;

  operationsForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    client_id_id: new FormControl(''),
    client_id_name: new FormControl(''),
    project_id_id: new FormControl(''),
    project_id_name: new FormControl(''),
    vendor_id_id: new FormControl(''),
    vendor_id_name: new FormControl(''),

    client_id: new FormControl(""),
    project_id: new FormControl(""),
    vendor_id: new FormControl(""),

    booking_id: new FormControl(""),


    value: new FormControl(''),
    custom_field_id: new FormControl('-1'),
    description: new FormControl(''),

    account_filter_type: new FormControl(''),
    account_id: new FormControl(''),


    page: new FormControl("1"),
    pagination: new FormControl(this.dataHolderService.paginationDefault, [Validators.required]),
    sort_by: new FormControl("updated_at"),
    sort_order: new FormControl("DESC"),
    report_type: new FormControl("pdf"),


  });

  tabularData: Observable<any>;

  paginationLinks: any;

  totalResults: number = 0;

  isFormVisible: boolean = false;

  constructor(
    private router: Router,
    public filterCustomFieldValuesService: FilterCustomFieldValuesService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Project / Sales Custom Field Values


    if (this.type_of_data == '1') {
      this.route.params.subscribe((params: Params) => {
        let account_filter_types = ['is_client', 'is_vendor', 'is_broker', 'is_employee', 'is_admin', 'is_super_admin', 'is_developer'];
        if (account_filter_types.includes(params['accountFilterType'])) {
          // console.log(params);
          this.operationsForm.patchValue({
            account_filter_type: params['accountFilterType']
          });

          this.onFilterCustomFieldValues();
        }
        else {
          // setTimeout(() => {
          this.router.navigate(['/auth/login']);
          // }, 500);
        }

      });
    }





    if (this.type_of_data == '2') {
      this.dataHolderService.updatePageMeta('Filter Attachments | Projects');

    }

    // Accounts Custom Field Values

    if (this.type_of_data == '1') {
      this.dataHolderService.updatePageMeta('Filter Attachments | Accounts');
    }


    if (this.type_of_data == '3') {
      this.dataHolderService.updatePageMeta('Filter Attachments  | Vendor Bookings / Purchases');

    }





    this.checkForQueryParams();
    this.onFilterCustomFieldValues();
  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {


      this.preselectedClientValue = { id: '-1', name: 'Type here' };
      this.hasPreSelectedClientValue = false;
      this.operationsForm.controls.client_id_id.setValue('-1');
      this.operationsForm.controls.client_id_name.setValue('');

      this.preselectedVendorValue = { id: '-1', name: 'Type here' };
      this.hasPreSelectedVendorValue = false;
      this.operationsForm.controls.vendor_id_id.setValue('-1');
      this.operationsForm.controls.vendor_id_name.setValue('');

      this.preselectedProjectValue = { id: '-1', name: 'Type here' };
      this.hasPreSelectedProjectValue = false;
      this.operationsForm.controls.project_id_id.setValue('-1');
      this.operationsForm.controls.project_id_name.setValue('');




      let hasQueryParams = false;

      if ('id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          id: queryParams.id
        });
      }

      if ('booking_id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          booking_id: queryParams.booking_id
        });
      }



      if ('value' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          value: queryParams.value
        });
      }



      if ('description' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          description: queryParams.description
        });
      }

      if ('custom_field_id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          custom_field_id: queryParams.custom_field_id
        });
      }


      if ('account_filter_type' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          account_filter_type: queryParams.account_filter_type
        });
      }

      if ('account_id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          account_id: queryParams.account_id
        });
      }



      if ('client_id_id' in queryParams && queryParams.client_id_id.length > 0 && queryParams.client_id_id != '-1') {
        hasQueryParams = true;

        let client_id_name = 'Client ID - ' + queryParams.client_id_id;

        if ('client_id_name' in queryParams && queryParams.client_id_name.length > 0) {
          client_id_name = queryParams.client_id_name;
        }

        this.operationsForm.patchValue({
          client_id_id: queryParams.client_id_id
        });

        this.operationsForm.patchValue({
          client_id_name: client_id_name
        });

        this.preselectedClientValue = { id: queryParams.client_id_id, name: client_id_name };
        this.hasPreSelectedClientValue = true;
      }

      if ('vendor_id_id' in queryParams && queryParams.vendor_id_id.length > 0 && queryParams.vendor_id_id != -1) {
        hasQueryParams = true;

        let vendor_id_name = 'Vendor ID - ' + queryParams.vendor_id_id;

        if ('vendor_id_name' in queryParams && queryParams.vendor_id_name.length > 0) {
          vendor_id_name = queryParams.vendor_id_name;
        }

        this.operationsForm.patchValue({
          vendor_id_id: queryParams.vendor_id_id
        });

        this.operationsForm.patchValue({
          vendor_id_name: vendor_id_name
        });

        this.preselectedVendorValue = { id: queryParams.vendor_id_id, name: vendor_id_name };
        this.hasPreSelectedVendorValue = true;
      }

     

      if ('project_id_id' in queryParams && queryParams.project_id_id.length > 0 && queryParams.project_id_id != -1) {
        hasQueryParams = true;

        let project_id_name = 'Project ID - ' + queryParams.project_id_id;

        if ('project_id_name' in queryParams && queryParams.project_id_name.length > 0) {
          project_id_name = queryParams.project_id_name;
        }

        this.operationsForm.patchValue({
          project_id_id: queryParams.project_id_id
        });

        this.operationsForm.patchValue({
          project_id_name: project_id_name
        });

        this.preselectedProjectValue = { id: queryParams.project_id_id, name: project_id_name };
        this.hasPreSelectedProjectValue = true;
      }



      if ('page' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          page: queryParams.page
        });
      }

      if ('pagination' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          pagination: queryParams.pagination
        });
      }


      if ('sort_by' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          sort_by: queryParams.sort_by
        });
      }



      if ('sort_order' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          sort_order: queryParams.sort_order
        });
      }

      if ('report_type' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          report_type: queryParams.report_type
        });
      }






      if (hasQueryParams) {
        // console.log(hasQueryParams);
        this.onFilterCustomFieldValues();
      }




      // console.log(hasQueryParams);





    });

  }

  updatePageNumber(e): void {

    this.operationsForm.patchValue({
      page:
        e.target.getAttribute('data-page-number') == null
          ? 1
          : e.target.getAttribute('data-page-number'),
    });
  }

  dropDownsUpdate(): void {
    if (this.operationsForm.value.client_id.client_id != '-1' && this.operationsForm.value.client_id.client_id != '' && this.operationsForm.value.client_id.client_id != null) {

      this.operationsForm.controls.client_id_id.setValue(this.operationsForm.value.client_id.client_id);
      this.operationsForm.controls.client_id_name.setValue(this.operationsForm.value.client_id.name);


    }

    if (this.operationsForm.value.vendor_id.vendor_id != '-1' && this.operationsForm.value.vendor_id.vendor_id != '' && this.operationsForm.value.vendor_id.vendor_id != null) {

      this.operationsForm.controls.vendor_id_id.setValue(this.operationsForm.value.vendor_id.vendor_id);
      this.operationsForm.controls.vendor_id_name.setValue(this.operationsForm.value.vendor_id.name);


    }

    if (this.operationsForm.value.project_id.project_id != '-1' && this.operationsForm.value.project_id.project_id != '' && this.operationsForm.value.project_id.project_id != null) {

      this.operationsForm.controls.project_id_id.setValue(this.operationsForm.value.project_id.project_id);
      this.operationsForm.controls.project_id_name.setValue(this.operationsForm.value.project_id.name);


    }

  }

  onFilterCustomFieldValues(): void {


    this.dropDownsUpdate();

    this.alertsService.removeAlert();
    this.validate = true;

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    this.loading = true;

    // Project / Sales  Custom Field Valuyes
    if (this.type_of_data == 2) {
      this.dataHolderService.updatePageMeta(
        `Filter Attachments | Projects | Page - ${this.operationsForm.value.page}`
      );
    }


    // Accounts Custom Field
    if (this.type_of_data == 1) {
      this.dataHolderService.updatePageMeta(
        `Filter Attachments | Accounts | Page - ${this.operationsForm.value.page}`
      );
    }


    
    // Vendor Bookings / Purchases Custom Field
    if (this.type_of_data == 3) {
      this.dataHolderService.updatePageMeta(
        `Filter Attachments | Vendor Bookings / Purchases | Page - ${this.operationsForm.value.page}`
      );
    }


    this.alertsService.addAlert('info', 'Filtering Attachments..');

    this.filterCustomFieldValuesService.filterCustomFieldValues(this.operationsForm.value, this.type_of_data).subscribe(
      (response: any) => {
        this.alertsService.removeAlert();
        this.loading = false;


        if (!response.status) {
          this.alertsService.addAlert("danger", response.message);
          this.totalResults = 0;
          return;
        }

        this.totalResults = response.total_results;
        this.tabularData = response.data.data;
        this.paginationLinks = response.pagination_data;
        this.alertsService.addAlert("success", response.message);

        // console.log(this.tabularData);

      },
      (error) => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      }
    );

  }

  getDownloadUrl() {
    if (this.type_of_data == '1') {
      return '/auth/download-custom-field-values';
    }
    if (this.type_of_data == '2') {
      return '/projects/download-custom-field-values';
    }
    if (this.type_of_data == '3') {
      return '/vendor-bookings/download-custom-field-values';
    }
  }

}
