<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{ isCreate ? 'ADD ITEM / MATERIAL USAGE' : 'UPDATE ITEM / MATERIAL USAGE' }}</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm">

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>



                        </div>


                        <div class="mt-2 mb-2 font-italic">

                            <p> <i class="fa fa-info-circle"></i> Operations perfomed here do not effect Inventory, The purpose of this section is keeping track of Item / Material Usage </p>
                        
            
                        </div>
                        
                        <div class="form-row">

                            <div class="col-md-6 mb-3">

                                <ng-container *ngIf="!isCreate">
                                    <label for="id">Record ID : </label>

                                    <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                        formControlName="id" readonly>
                                </ng-container>



                            </div>

                            <div class="col-md-6 mb-3">

                                <label for="transaction_date">  Date : </label>
                                <input class="form-control" id="transaction_date" type="date"
                                    placeholder="Item Order Date" formControlName="transaction_date"
                                    name="transaction_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['transaction_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                        </div>


                        <div class="form-row">
                            <div class="col-md-12 mb-3">

                                <label for="item_id">Item or Material</label>

                                <ng-select name="item_id" [items]="dataHolderService.available_booking_items"
                                    [virtualScroll]="true"
                                    class="form-control form-control-primary btn-square ng-select-outline"
                                    bindLabel="name" bindValue="id" placeholder="Select Item" formControlName="item_id">

                                    <ng-template ng-label-tmp let-item="item" let-index="index">
                                        <img class="m-1 rounded-circle" height="10" width="10"
                                            src="
                                    {{ (item.attachment_url &&  item.attachment_url.length > 5) ? (dataHolderService.apiBaseUrl + '/vendor-bookings/get-available-booking-item-attachment/' + item.id ) : '/assets/images/dummy-material.png'}}" />
                                         <small> {{item.name}} <span class="m-2"> ( ID : {{ item.id }} )
                                                </span> </small> 



                                        <small *ngIf="item.label_value"> <small class="m-1" *ngFor="let key of Object.keys(item.label_value)"> <span
                                                    class="badge badge-info">
                                                    {{ item.label_value[key].label.name }} </span> </small> </small>


                                    </ng-template>

                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <!-- -->
                                        <div style="border-top: 1px solid black;"> <img class="m-2 rounded-circle"
                                                height="25" width="25"
                                                src="
                                        {{ (item.attachment_url &&  item.attachment_url.length > 5) ? (dataHolderService.apiBaseUrl + '/vendor-bookings/get-available-booking-item-attachment/' + item.id ) : '/assets/images/dummy-material.png'}}" />
                                            <b>{{item.name}}</b> <span class="m-2"> ( ID : {{ item.id }} ) </span>

                                            <br>

                                            <small class="m-1" style="display: inline-block;"
                                                *ngFor="let key of Object.keys(item.label_value)"> <span
                                                    class="badge badge-info">
                                                    {{ item.label_value[key].label.name }} </span> </small>
                                        </div>

                                    </ng-template>

                                </ng-select>


                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.item_id == '-1'">
                                        *Please select Item
                                    </span>
                                </div>

                                <p><a routerLink="/settings/available-booking-items/create"><small>Add new Item /
                                            Material </small></a></p>


                            </div>
                        </div>


                        <div class="form-row">


                            <div class="col-md-6 mb-3">
                                <label for="title"> Title (Notes) : </label>
                                <input class="form-control" id="title" formControlName="title" name="title" />
                           
                                <div class="form-error-message">

                                </div>

                            </div>





                        </div>

                        <div class="form-row">

                            <div class="col-md-6 mb-3">

                                <label for="unit_id">Unit of measurement</label>
                                <select class="form-control" id="unit_id" type="text" placeholder="Unit of measurement"
                                    formControlName="unit_id" name="unit_id">
                                    <option value="-1">NA</option>
                                    <option *ngFor="let key of Object.keys(dataHolderService.units)"
                                        [value]="dataHolderService.units[key].id">
                                        {{ dataHolderService.units[key].fullform }} (
                                        {{ dataHolderService.units[key].symbol }} )
                                    </option>
                                </select>
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.unit_id == '-1'">
                                        *Please select Unit of measurement
                                    </span>
                                </div>

                                <p><a routerLink="/settings/units/create"><small>Add new Unit of measurement
                                        </small></a></p>





                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="quantity">No. of Units </label>

                                <input class="form-control" id="quantity" step="any" type="number"
                                    placeholder="Quantity" formControlName="quantity" name="quantity">



                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['quantity'].errors?.required">
                                        *required</span>
                                </div>
                            </div>


                        </div>




                        <div class="form-row">
                            <div class="col-md-12 mt-3 mb-3">
                                <label for="details"> Additional Notes : </label>
                                <p> <small> </small> </p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 preview-details">
                                <angular-editor id="details" [config]="dataHolderService.kolkovEditorConfig"
                                    formControlName="details"></angular-editor>

                            </div>
                        </div>

                     

            

                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD USAGE' : 'UPDATE USAGE' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary m-2" [class.loader--text]="loading"
                            [disabled]="loading" type="button"><span>{{ loading ? '' : ' REMOVE USAGE' }}</span></button>

                    </form>

                  
                </div>
            </div>
        </div>
    </div>
</div>