import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDates'
})
export class MomentDatesPipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @param requiredDate - today or week or month  
   * @param startOrEnd  - Start or End of the Week / Month
   * @returns 
   */
  transform(value: Date | string | moment.Moment, requiredDate : string = 'today' , startOrEnd : string ='' , dateFormat : string = 'YYYY-MM-DD'  ): any {

    const today = moment();

    if(requiredDate == 'today'){
       return today.format(dateFormat);
    }

    if(requiredDate == 'week'){
      return startOrEnd == 'start' ? today.startOf('week').format(dateFormat) : today.endOf('week').format(dateFormat);
    }

    if(requiredDate == 'month'){
      return startOrEnd == 'start' ? today.startOf('month').format(dateFormat) : today.endOf('month').format(dateFormat);
    }

  }

}
