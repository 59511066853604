<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{ isCreate ? 'ADD ITEM' : 'UPDATE ITEM' }}</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm">

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>



                        </div>


                        <div class="form-row">

                            <div class="col-md-6 mb-3">

                                <ng-container *ngIf="!isCreate">
                                    <label for="id">Record ID : </label>

                                    <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                        formControlName="id" readonly>
                                </ng-container>



                            </div>

                            <div class="col-md-6 mb-3">

                                <label for="item_order_date">Item Order Date : </label>
                                <input class="form-control" id="item_order_date" type="date"
                                    placeholder="Item Order Date" formControlName="item_order_date"
                                    name="item_order_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['item_order_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                        </div>


                        <div class="form-row">
                            <div class="col-md-12 mb-3">

                                <label for="item_id">Item or Material</label>

                                <ng-select name="item_id" [items]="dataHolderService.available_booking_items"
                                    [virtualScroll]="true"
                                    class="form-control form-control-primary btn-square ng-select-outline"
                                    bindLabel="name" bindValue="id" placeholder="Select Item" formControlName="item_id">

                                    <ng-template ng-label-tmp let-item="item" let-index="index">
                                        <img class="m-1 rounded-circle" height="10" width="10"
                                            src="
                                    {{ (item.attachment_url &&  item.attachment_url.length > 5) ? (dataHolderService.apiBaseUrl + '/vendor-bookings/get-available-booking-item-attachment/' + item.id ) : '/assets/images/dummy-material.png'}}" />
                                         <small> {{item.name}} <span class="m-2"> ( ID : {{ item.id }} )
                                                </span> </small> 



                                        <small *ngIf="item.label_value"> <small class="m-1" *ngFor="let key of Object.keys(item.label_value)"> <span
                                                    class="badge badge-info">
                                                    {{ item.label_value[key].label.name }} </span> </small> </small>


                                    </ng-template>

                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <!-- -->
                                        <div style="border-top: 1px solid black;"> <img class="m-2 rounded-circle"
                                                height="25" width="25"
                                                src="
                                        {{ (item.attachment_url &&  item.attachment_url.length > 5) ? (dataHolderService.apiBaseUrl + '/vendor-bookings/get-available-booking-item-attachment/' + item.id ) : '/assets/images/dummy-material.png'}}" />
                                            <b>{{item.name}}</b> <span class="m-2"> ( ID : {{ item.id }} ) </span>

                                            <br>

                                            <small class="m-1" style="display: inline-block;"
                                                *ngFor="let key of Object.keys(item.label_value)"> <span
                                                    class="badge badge-info">
                                                    {{ item.label_value[key].label.name }} </span> </small>
                                        </div>

                                    </ng-template>

                                </ng-select>

                                <p *ngIf="operationsForm.value.item_id && operationsForm.value.item_id!='-1'">
                                    <small>Item ID : <a target="_blanc" [routerLink]="[ '/settings/available-booking-items/edit/' ,operationsForm.value.item_id ]"
                                            class="li">
                                            {{ operationsForm.value.item_id }} </a>
                                    </small>
                                </p>


                             
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.item_id == '-1'">
                                        *Please select Item
                                    </span>
                                </div>

                                <p><a routerLink="/settings/available-booking-items/create"><small>Add new Item /
                                            Material </small></a></p>


                            </div>
                        </div>


                        <div class="form-row">


                            <div class="col-md-6 mb-3">
                                <label for="title"> Title (Item Small Description) : </label>
                                <input class="form-control" id="title" formControlName="title" name="title" />
                                <p> A smal description about this booking item. You can display this information in
                                    Vouchers / Invoices </p>
                                <div class="form-error-message">

                                </div>

                            </div>



                            <div class="col-md-6 mb-3">

                                <label for="taxes_applicable">Taxes Applicable?</label>
                                <select class="form-control" id="taxes_applicable" type="text"
                                    placeholder="Taxes Applicable?" formControlName="taxes_applicable"
                                    name="taxes_applicable">

                                    <option [value]="0">No</option>
                                    <option [value]="1">Yes </option>



                                </select>

                                <p *ngIf="isCreate && operationsForm.value.taxes_applicable == '1'"> You can add taxes
                                    in the next step </p>

                                <p *ngIf="!isCreate && operationsForm.value.taxes_applicable == '1'"> Scroll to the
                                    bottom to add taxes to this item </p>

                                <p *ngIf="!isCreate && operationsForm.value.taxes_applicable == '0'">
                                    <small>Up on saving changes, Existing Tax Information for item will be removed
                                    </small>
                                </p>
                                <div class="form-error-message"></div>

                            </div>



                        </div>

                        <div class="form-row">

                            <div class="col-md-4 mb-3">

                                <label for="unit_id">Unit of measurement</label>
                                <select class="form-control" id="unit_id" type="text" placeholder="Unit of measurement"
                                    formControlName="unit_id" name="unit_id">
                                    <option value="-1">NA</option>
                                    <option *ngFor="let key of Object.keys(dataHolderService.units)"
                                        [value]="dataHolderService.units[key].id">
                                        {{ dataHolderService.units[key].fullform }} (
                                        {{ dataHolderService.units[key].symbol }} )
                                    </option>
                                </select>
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.unit_id == '-1'">
                                        *Please select Unit of measurement
                                    </span>
                                </div>

                                <p><a routerLink="/settings/units/create"><small>Add new Unit of measurement
                                        </small></a></p>





                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="quantity">No. of Units </label>

                                <input class="form-control" id="quantity" step="any" type="number"
                                    placeholder="Quantity" formControlName="quantity" name="quantity">



                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['quantity'].errors?.required">
                                        *required</span>
                                </div>

                                <div *ngIf="operationsForm.value.quantity!=0 && operationsForm.value.quantity > 0 && operationsForm.value.quantity!='' && operationsForm.value.item_id !='-1' && operationsForm.value.item_id !='' "> 
                                    <span> Default Per Unit Price :{{ displayDefaultPrice() | indianNumberFormat }} </span> <br>
                                    <span (click)="operationsForm.controls.price.setValue(displayPriceOfAllUnits())"> <span style="text-decoration: underline;"> Click to Replace Price </span>  with Total Price calculated using default Price : {{ displayPriceOfAllUnits() | indianNumberFormat }} </span> <br>

                                </div>
                            </div>


                            <div class="col-md-4 mb-3">
                                <label for="price"> Price <small> (Of all Units and with out taxes ) </small> </label>

                                <input class="form-control" id="price" step="any" type="number" placeholder="Price"
                                    formControlName="price" name="price">

                                <ng-container *ngIf="operationsForm.value.price > 0">
                                    <p><small>{{ operationsForm.value.price | indianNumberFormat  }}</small>
                                        <small> (
                                            {{ operationsForm.value.price | numberToIndianWords  }} )
                                        </small>
                                    </p>

                                </ng-container>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['price'].errors?.required">
                                        *required</span>
                                </div>
                            </div>



                        </div>




                        <div class="form-row">
                            <div class="col-md-12 mt-3 mb-3">
                                <label for="details"> Additional Notes : </label>
                                <p> <small> </small> </p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 preview-details">
                                <angular-editor id="details" [config]="dataHolderService.kolkovEditorConfig"
                                    formControlName="details"></angular-editor>

                            </div>
                        </div>


                        <div class="form-row mb-3 mt-3">

                            <div class="col-md-12">

                                <label for="update_inventory">Update Inventory ?</label>
                                <select class="form-control" id="update_inventory" type="text"
                                    placeholder="Taxes Applicable?" formControlName="update_inventory"
                                    name="update_inventory">

                                    <option [value]="0">No</option>
                                    <option [value]="1">Yes </option>



                                </select>

                                <div class="mt-2 mb-2 font-italic">

                                    <p> <i class="fa fa-info-circle"></i> If you select Yes, system will check for a
                                        Inventroy record matching Item and Unit Combination. If no record found, System
                                        return an Error </p>
                                    <p> <i class="fa fa-info-circle"></i> If there is not Enough Inventory to perform
                                        necessary action, system returns error. </p>
                                    <p> <i class="fa fa-info-circle"></i> To perform any action related to Inventory,
                                        first you have to create an Inventory record for the Item and Unit Combination.
                                    </p>
                                    <p> <i class="fa fa-info-circle"></i> Use the same Item and Unit Combination in all
                                        places of the application </p>
                                    <p> <i class="fa fa-info-circle"></i> Item Order Date will be used to log Inventory
                                        Logs </p>


                                    <ng-container *ngIf="type_of_data == '2'">
                                        <p *ngIf="isCreate"> <i class="fa fa-info-circle"></i> If you select Yes,
                                            Inventory Stock = Previous Stock - New Quantity </p>
                                        <p *ngIf="!isCreate"> <i class="fa fa-info-circle"></i> If you select Yes,
                                            Inventory Stock = Previous Stock + Old Quantity - Quantity </p>

                                    </ng-container>


                                    <ng-container *ngIf="type_of_data == '3'">
                                        <p *ngIf="isCreate"> <i class="fa fa-info-circle"></i> If you select Yes,
                                            Inventory Stock = Previous Stock + New Quantity </p>
                                        <p *ngIf="!isCreate"> <i class="fa fa-info-circle"></i> If you select Yes,
                                            Inventory Stock = Previous Stock - Old Quantity + New Quantity </p>

                                    </ng-container>


                                </div>



                                <div class="form-error-message"></div>

                            </div>



                        </div>


                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD ITEM' : 'UPDATE ITEM' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary m-2" [class.loader--text]="loading"
                            [disabled]="loading" type="button"><span>{{ loading ? '' : ' REMOVE ITEM' }}</span></button>

                    </form>

                    <div class="card-body">
                        <app-display-tax-unit-values *ngIf="!isCreate && operationsForm.value.taxes_applicable == '1'"
                            [type_of_data]="type_of_data == '2' ? '21' : '7' " [table_column_id]="fetchedData.id"
                            [basePrice]="fetchedData.price" [totalTax]="fetchedData.calculated_total_tax">
                        </app-display-tax-unit-values>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>