import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'profiles',
    loadChildren: () => import('../../profiles/profiles.module').then(m => m.ProfilesModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('../../accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('../../projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'vendor-bookings',
    loadChildren: () => import('../../vendor-bookings/vendor-bookings.module').then(m => m.VendorBookingsModule)
  },
  {
    path: 'labour-management',
    loadChildren: () => import('../../labour-management/labour-management.module').then(m => m.LabourManagementModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];
