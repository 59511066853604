import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CustomFieldValuesService {


  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  fetchCustomFieldValues(type_of_data, table_column_id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-custom-field-values/${type_of_data}/${table_column_id}`);

  }


  fetchCustomFieldValueById(id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-custom-field-value-info/${id}`);

  }

  addCustomFieldValue(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/add-custom-field-value', body);

  }

  updateCustomFieldValue(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/update-custom-field-value', body);

  }

  deleteAttachment(id) {
    let body = new HttpParams();
    body = body.set('id', id);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/shared/delete-custom-field-value-attachment', {
      params: body
    });

  }

  deleteCustomFieldValue(id) {
    let body = new HttpParams();
    body = body.set('id', id);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/shared/delete-custom-field-value', {
      params: body
    });

  }


}
