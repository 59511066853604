<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Manage Labels </h5>
                </div>
                <div class="card-body">
                    <!-- <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm"> -->


                    <div class="form-row">
                        <div class="col-md-12 mb-1">
                            <a (click)="reloadData()" class="link-dark"> <i class="fa fa-rotate-right"></i> Refresh </a>

                        </div>

                    </div>


                    <div class="form-row mb-1 mt-1">

                        <div class="col-md-12 mb-1">
                            <label for="selectedLabels"> Select Labels : </label>

                        </div>


                    </div>

                    <div class="form-row mb-1 mt-1">


                        <div class="col-md-12 mb-1">
                            <ng-select [items]="dataHolderService.labels" [multiple]=" true" bindLabel="name"
                                [selectableGroup]="true" [closeOnSelect]="false" bindValue="id" [virtualScroll]="true"
                                [(ngModel)]="selectedLabels">

                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input class="m-1" id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                                    {{item.name}}
                                </ng-template>
                            </ng-select>

                        </div>

                        <div class="col-md-12 mt-3">
                            <p>
                                <a class="m-1" routerLink="/settings/labels/create" target="_blanc"><small>Add new
                                        Label</small></a>
                                <a class="m-1" routerLink="/settings/labels" target="_blanc"><small>View
                                        Labels</small></a>

                            </p>

                            <app-refresh-fields-data></app-refresh-fields-data>


                        </div>







                    </div>

                    <button (click)="onOperation()" type="submit" class="btn btn-pill btn-air-primary btn-primary mt-5"
                        [class.loader--text]="loading" [disabled]="loading" type="submit">
                        <span>{{ loading ? '' : ( 'Done' ) }}</span></button>


                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</div>