<div class="media profile-media">
  <!-- <img class="b-r-10"   src="{{  dataHolderService.userInfo &&  dataHolderService.userInfo.profile_picture_url &&  dataHolderService.userInfo.profile_picture_url.length > 5 ? dataHolderService.apiBaseUrl + '/auth/get-profile-photo/' + dataHolderService.userInfo.id : 'assets/images/dashboard/profile.jpg' }}   " alt=""> -->
  <div class="media-body"><span>{{ dataHolderService.userInfo ? dataHolderService.userInfo.name  : '' }}</span>
    <p class="mb-0 font-roboto"> More  <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account ID : {{ dataHolderService.userInfo ? dataHolderService.userInfo.id  : '' }} </span></a></li>
  <li><a [routerLink]="[ '/accounts/change-password' ]" ><app-feather-icons [icon]="'lock'"></app-feather-icons><span> Change Password </span></a></li> -->
  <li (click)="dataHolderService.logout()"><a href="#"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></a></li>
</ul>