import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class RemaindersService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  fetchRemainders(type_of_data, table_column_id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-remainders/${type_of_data}/${table_column_id}`);

  }


  fetchRemainderById(id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-remainder-info/${id}`);

  }

  addRemainder(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/add-remainder', body);

  }

  updateRemainder(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/update-remainder', body);

  }


  deleteRemainder(id) {
    let body = new HttpParams();
    body = body.set('id', id);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/shared/delete-remainder', {
      params: body
    });

  }

}