import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LabelValuesService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }


  manageLabels(type_of_data, table_column_id , body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + `/shared/manage-labels/${type_of_data}/${table_column_id}` , body);

  }


}
