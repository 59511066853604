import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MaterialUsedService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }


  fetchMaterialUsed(type_of_data, table_column_id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/vendor-bookings/get-material-used/${type_of_data}/${table_column_id}`);

  }


  fetchMaterialUsedById(id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/vendor-bookings/get-material-used-info/${id}`);

  }

  addMaterialUsed(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/vendor-bookings/add-material-used', body);

  }

  updateMaterialUsed(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/vendor-bookings/update-material-used', body);

  }

  deleteMaterialUsed(id) {
    let body = new HttpParams();
    body = body.set('id', id);

    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/vendor-bookings/delete-material-used', {
      params: body
    });

  }

}
