<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{ isCreate ? 'ADD ADDITIONAL INFORMATION' : 'UPDATE ADDITIONAL INFORMATION' }}</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm"
                        id="customFieldFormWithData">

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>

                        </div>

                        <div *ngIf="!isCreate" class="form-row">

                            <div class="col-md-4 mb-3">
                                <label for="id">ID : </label>

                                <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                    formControlName="id" readonly>

                            </div>

                        </div>



                        <div class="form-row">


                            <div class="col-md-6 mb-3">
                                <label for="custom_field_id ">Custom Field : </label>

                                <select class="form-control" placeholder="Custom Field.."
                                    formControlName="custom_field_id" name="custom_field_id" id="custom_field_id">

                                    <option *ngFor="let key of Object.keys(dataHolderService.customFields)"
                                        [value]="dataHolderService.customFields[key].id">
                                        {{ dataHolderService.customFields[key].name }}
                                    </option>

                                </select>

                                <div class="mt-1 mb-1">
                                    <a routerLink="/settings/custom-fields/create"> Not in the List? Add Here </a>
                                    <br>
                                    <a routerLink="/settings/custom-fields"> Manage Custom Fields </a>
                                    <br>

                                </div>
                            </div>



                            <div class="col-md-6 mb-3">
                                <label for="value">Value : <span> * </span> </label>
                                <input class="form-control" id="value" formControlName="value" name="value" />

                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['value'].errors?.required">
                                        *required</span>
                                </div>

                            </div>




                        </div>

                        <div class="form-row mt-3 mb-3">
                            <div class="col-md-12 mb-3">
                                <label for="attachment_url">Attachment</label>
                                <input name="attachment_url" type="file"
                                    formControlName="attachment_url" class="form-control">



                            </div>

                            <div class="col-md-12 mb-3">

                                <div class="mt-3"
                                    *ngIf="!isCreate &&  fetchedCustomFieldValue && fetchedCustomFieldValue.attachment_url && fetchedCustomFieldValue.attachment_url.length > 5">



                                    <div class="mt-3">


                                        <p>

                                            <a href="{{ dataHolderService.apiBaseUrl + '/shared/get-custom-field-value-attachment/' + this.fetchedCustomFieldValue.id }}"
                                                class=" m-3">

                                                <small>
                                                    {{ this.fetchedCustomFieldValue.client_side_name ? this.fetchedCustomFieldValue.client_side_name : ""  }}
                                                </small>

                                                <br>
                                                <i class="fa fa-cloud-download"></i> Download </a>

                                            <a style="cursor: pointer;" (click)="deleteAttachment()"
                                                class="text-danger m-3"> <i class="fa fa-trash-o"></i> Delete
                                            </a>

                                        </p>










                                    </div>

                                </div>

                            </div>


                        </div>

                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="sort_order">Sort Order : </label>
                                <input class="form-control" id="sort_order" formControlName="sort_order"
                                    name="sort_order" />
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="description">Description : </label>
                                <textarea class="form-control" id="description" rows="6" formControlName="description"
                                    name="description"></textarea>
                            </div>
                        </div>






                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD CUSTOM FIELD' : 'UPDATE CUSTOM FIELD' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary M-2" [class.loader--text]="loading"
                            [disabled]="loading"
                            type="button"><span>{{ loading ? '' : ' REMOVE CUSTOM FIELD' }}</span></button>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>