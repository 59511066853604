import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TransactionRecordsService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  fetchTransactionRecords(type_of_data, table_column_id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-transaction-records/${type_of_data}/${table_column_id}`);

  }


  fetchTransactionRecordById(id ): any {
    
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-transaction-record-info/${id}`);

  }

  addTransactionRecord(body , type_of_data : any = '' ): any {

    if(type_of_data == '4'){
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/add-transaction-record-labour-work', body);

    }
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/add-transaction-record', body);

  }

  updateTransactionRecord(body , type_of_data : any = '' ): any {

    if(type_of_data == '4'){
         return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/update-transaction-record-labour-work', body);

    }
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/update-transaction-record', body);

  }


  deleteTransactionRecord(id , type_of_data : any = '' ) {
    let body = new HttpParams();
    body = body.set('id', id);

    if(type_of_data == '4'){

      return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/shared/delete-transaction-record-labour-work', {
        params: body
      });

    }
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/shared/delete-transaction-record', {
      params: body
    });

  }

}
