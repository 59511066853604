<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{ isCreate ? 'ADD REMAINDER' : 'UPDATE REMAINDER' }}</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm">

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>



                        </div>

                        <div *ngIf="!isCreate" class="form-row">

                            <div class="col-md-4 mb-3">
                                <label for="id">ID : </label>

                                <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                    formControlName="id" readonly>

                            </div>

                        </div>


                        <div class="form-row">


                            <div class="col-md-6 mb-3">
                                <label for="title"> Title : </label>
                                <input class="form-control" id="title" formControlName="title" name="title" />
                                <div class="form-error-message">

                                </div>

                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="status">Status</label>
                                <select class="form-control" id="status" type="text" placeholder="Status"
                                    formControlName="status" name="status">

                                    <option [value]="1">To Do</option>
                                    <option [value]="2">In Progress </option>
                                    <option [value]="3">Completed</option>




                                </select>
                                <div class="form-error-message"></div>
                            </div>



                        </div>

                        <div class="form-row">

                            <div class="col-md-4 mb-3">

                                <label for="due_date">Due Date : </label>
                                <input class="form-control" id="due_date" type="date" placeholder="Due Date"
                                    formControlName="due_date" name="due_date">
                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['due_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                            <div class="col-md-4 mb-3">

                                <label for="completed_date">Completed Date : </label>
                                <input class="form-control" id="completed_date" type="date" placeholder="To Date"
                                    formControlName="completed_date" name="completed_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['completed_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="sort_order">Sort Order : </label>
                                <input class="form-control" id="sort_order" formControlName="sort_order"
                                    name="sort_order" />
                            </div>

                        </div>




                        <div class="form-row">
                            <div class="col-md-12 mt-3 mb-3">
                                <label for="description"> Additional Notes : </label>
                                <p> <small> You can receipts , images, notes etc </small> </p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 preview-details">
                                <angular-editor id="description" [config]="dataHolderService.kolkovEditorConfig"
                                    formControlName="description"></angular-editor>

                            </div>
                        </div>


                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD REMAINDER' : 'UPDATE REMAINDER' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary m-2" [class.loader--text]="loading"
                            [disabled]="loading"
                            type="button"><span>{{ loading ? '' : ' REMOVE REMAINDER' }}</span></button>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>