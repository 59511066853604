<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type"
        [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <app-alerts></app-alerts>

          <div class="row">
            <div class="col-sm-12 mt-2">
              <div class="card  b-r-0 mb-0">
                <div class="card-header bg-gradient-animation  b-r-0">


                  <h5 style="color: white !important;">
                    <ng-container
                      *ngIf=" dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO && dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO.attachment_url && dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO.attachment_url.length > 5 ">
                      <img width="30" height="30"
                        src="{{  dataHolderService.application_configuration.CONFIGURATION_COMPANY_LOGO.attachment_url   }}">
                    </ng-container>
                    {{ dataHolderService.application_configuration.CONFIGURATION_COMPANY_NAME ?  dataHolderService.application_configuration.CONFIGURATION_COMPANY_NAME.value : '' }}
                    <br>
                    <small class=""> <small>  <i>Hi {{ dataHolderService.userInfo ? dataHolderService.userInfo.name  : '' }},  Welcome to BERP </i> </small>   </small>  
                  </h5>

   
                </div>

              </div>

            </div>
          </div>



          <br>

          <router-outlet #o="outlet"></router-outlet>

        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
</div>
<!-- page-wrapper End-->