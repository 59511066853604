import { RemaindersService } from './../services/remainders.service';
import { AlertsService } from './../services/alerts.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataHolderService } from '../services/data-holder.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-remainders-add-edit',
  templateUrl: './remainders-add-edit.component.html',
  styleUrls: ['./remainders-add-edit.component.scss']
})
export class RemaindersAddEditComponent implements OnInit {

 
  
  @Input() isCreate: boolean | string = false;

  @Input() type_of_data: number | string;

  @Input() is_credit : number | string = 1;


  @Input() table_column_id: number | string = '';

  @Output() reloadData = new EventEmitter<any>();

  Object = Object;

  public validate = false;

  public loading = false;

  operationsForm: FormGroup;

  @Input() fetchedData : any = null;

  isCreateSub: any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public remaindersService : RemaindersService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.operationsForm = this.formBuilder.group({
      id: ["-1", Validators.required],
      type_of_data: [this.type_of_data, Validators.required],
      table_column_id: [this.table_column_id, Validators.required],
      title: [''],
      description: [''],
      sort_order: ["0", []],
      status: ["1", []],
      due_date : [""],
      completed_date : [""],
      
    });

    if (!this.isCreate) {
      this.updateForm();
    }
  }

  onOperation(): any {

    this.alertsService.removeAlert();
    this.validate = true;
    this.loading = false;

    // console.log(this.operationsForm.invalid);

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    this.loading = true;
    const formData = this.operationsForm.value;


    if (this.isCreate) {
      this.alertsService.addAlert('info', 'Adding Data...');
      this.addOperation(formData);
      return;
    }
    else {
      this.alertsService.addAlert('info', 'Updating Data..');
      this.updateOperation(formData);
      return;
    }

  }

  addOperation(formData): void {

    this.remaindersService.addRemainder(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);


    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

  updateOperation(formData): void {


    this.remaindersService.updateRemainder(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });

  }

  updateForm(response : any = null) {
    if (response != null) {
      this.alertsService.addAlert("success", response.message);
    }

    if(response){
      this.fetchedData = response['data'];

    }


    this.operationsForm.controls.id.setValue(this.fetchedData.id);
    this.operationsForm.controls.type_of_data.setValue(this.fetchedData.type_of_data);
    this.operationsForm.controls.table_column_id.setValue(this.fetchedData.table_column_id);
    this.operationsForm.controls.title.setValue(this.fetchedData.title ? this.fetchedData.title : '');
    this.operationsForm.controls.description.setValue(this.fetchedData.description ? this.fetchedData.description : '');
    this.operationsForm.controls.status.setValue(this.fetchedData.status);
   


    if (this.fetchedData.due_date) {
      this.operationsForm.controls.due_date.setValue(this.fetchedData.due_date);

    }

    if (this.fetchedData.completed_date) {
      this.operationsForm.controls.completed_date.setValue(this.fetchedData.completed_date);

    }



  }

  deleteOperation() {
    this.alertsService.addAlert('info', 'Removing Data..');

    this.remaindersService.deleteRemainder(this.fetchedData.id).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

      // this.myProfileService.fetchedAccountInfo = response["user"];



    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }


}
