import { FilterApplicationFilesService } from './../services/filter-application-files.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertsService } from './../services/alerts.service';
import { DataHolderService } from 'src/app/shared/services/data-holder.service';


@Component({
  selector: 'app-filter-application-files',
  templateUrl: './filter-application-files.component.html',
  styleUrls: ['./filter-application-files.component.scss']
})
export class FilterApplicationFilesComponent implements OnInit {

  Object = Object;

  @Input() type_of_data: string | number;

  public validate = false;

  public loading = false;

  preselectedClientValue: any;

  hasPreSelectedClientValue: any = false;

  preselectedVendorValue: any;

  hasPreSelectedVendorValue: any = false;

  preselectedProjectValue: any;

  hasPreSelectedProjectValue: any = false;

  operationsForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    custom_id: new FormControl(''),

    client_id_id: new FormControl(''),
    client_id_name: new FormControl(''),
    project_id_id: new FormControl(''),
    project_id_name: new FormControl(''),
    vendor_id_id: new FormControl(''),
    vendor_id_name: new FormControl(''),

    client_id: new FormControl(""),
    project_id: new FormControl(""),
    vendor_id: new FormControl(""),

    booking_id : new FormControl(""),



    title: new FormControl(''),
    description: new FormControl(''),


    min_file_date: new FormControl(''),
    max_file_date: new FormControl(''),

    min_due_date: new FormControl(''),
    max_due_date: new FormControl(''),

    page: new FormControl("1"),
    pagination: new FormControl(this.dataHolderService.paginationDefault, [Validators.required]),
    sort_by: new FormControl("updated_at"),
    sort_order: new FormControl("DESC"),
    report_type: new FormControl("pdf"),


  });

  tabularData: Observable<any>;

  paginationLinks: any;

  totalResults: number = 0;

  isFormVisible: boolean = false;

  constructor(
    private router: Router,
    public filterApplicationFilesService: FilterApplicationFilesService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.type_of_data == '14') {
      this.dataHolderService.updatePageMeta('Filter Invoices | Projects');

    }

    if (this.type_of_data == '18') {
      this.dataHolderService.updatePageMeta('Filter Estimates | Projects');

    }

    if (this.type_of_data == '15') {
      this.dataHolderService.updatePageMeta('Filter Vouchers | Vendor Bookings');

    }

    this.checkForQueryParams();
    this.onFilterApplicationFiles();

  }

  checkForQueryParams(): void {

    this.route.queryParams.subscribe(queryParams => {


      this.preselectedClientValue = { id: '-1', name: 'Type here' };
      this.hasPreSelectedClientValue = false;
      this.operationsForm.controls.client_id_id.setValue('-1');
      this.operationsForm.controls.client_id_name.setValue('');

      
      this.preselectedVendorValue = { id: '-1', name: 'Type here' };
      this.hasPreSelectedVendorValue = false;
      this.operationsForm.controls.vendor_id_id.setValue('-1');
      this.operationsForm.controls.vendor_id_name.setValue('');

      this.preselectedProjectValue = { id: '-1', name: 'Type here' };
      this.hasPreSelectedProjectValue = false;
      this.operationsForm.controls.project_id_id.setValue('-1');
      this.operationsForm.controls.project_id_name.setValue('');




      let hasQueryParams = false;

      if ('id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          id: queryParams.id
        });
      }

      if ('custom_id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          custom_id: queryParams.custom_id
        });
      }

      if ('booking_id' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          booking_id: queryParams.booking_id
        });
      }



      if ('title' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          title: queryParams.title
        });
      }



      if ('description' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          description: queryParams.description
        });
      }




      if ('min_file_date' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          min_file_date: queryParams.min_file_date
        });
      }

      if ('max_file_date' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          max_file_date: queryParams.max_file_date
        });
      }





      if ('min_due_date' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          min_due_date: queryParams.min_due_date
        });
      }



      if ('max_due_date' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          max_due_date: queryParams.max_due_date
        });
      }






      if ('client_id_id' in queryParams && queryParams.client_id_id.length > 0 && queryParams.client_id_id != '-1') {
        hasQueryParams = true;

        let client_id_name = 'Client ID - ' + queryParams.client_id_id;

        if ('client_id_name' in queryParams && queryParams.client_id_name.length > 0) {
          client_id_name = queryParams.client_id_name;
        }

        this.operationsForm.patchValue({
          client_id_id: queryParams.client_id_id
        });

        this.operationsForm.patchValue({
          client_id_name: client_id_name
        });

        this.preselectedClientValue = { id: queryParams.client_id_id, name: client_id_name };
        this.hasPreSelectedClientValue = true;
      }

      if ('vendor_id_id' in queryParams && queryParams.vendor_id_id.length > 0 && queryParams.vendor_id_id != -1) {
        hasQueryParams = true;

        let vendor_id_name = 'Vendor ID - ' + queryParams.vendor_id_id;

        if ('vendor_id_name' in queryParams && queryParams.vendor_id_name.length > 0) {
          vendor_id_name = queryParams.vendor_id_name;
        }

        this.operationsForm.patchValue({
          vendor_id_id: queryParams.vendor_id_id
        });

        this.operationsForm.patchValue({
          vendor_id_name: vendor_id_name
        });

        this.preselectedVendorValue = { id: queryParams.vendor_id_id, name: vendor_id_name };
        this.hasPreSelectedVendorValue = true;
      }


      if ('project_id_id' in queryParams && queryParams.project_id_id.length > 0 && queryParams.project_id_id != -1) {
        hasQueryParams = true;

        let project_id_name = 'Project ID - ' + queryParams.project_id_id;

        if ('project_id_name' in queryParams && queryParams.project_id_name.length > 0) {
          project_id_name = queryParams.project_id_name;
        }

        this.operationsForm.patchValue({
          project_id_id: queryParams.project_id_id
        });

        this.operationsForm.patchValue({
          project_id_name: project_id_name
        });

        this.preselectedProjectValue = { id: queryParams.project_id_id, name: project_id_name };
        this.hasPreSelectedProjectValue = true;
      }



      if ('page' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          page: queryParams.page
        });
      }

      if ('pagination' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          pagination: queryParams.pagination
        });
      }


      if ('sort_by' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          sort_by: queryParams.sort_by
        });
      }



      if ('sort_order' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          sort_order: queryParams.sort_order
        });
      }

      if ('report_type' in queryParams) {
        hasQueryParams = true;
        this.operationsForm.patchValue({
          report_type: queryParams.report_type
        });
      }






      if (hasQueryParams) {
        // console.log(hasQueryParams);
        this.onFilterApplicationFiles();
      }




      // console.log(hasQueryParams);





    });

  }

  updatePageNumber(e): void {

    this.operationsForm.patchValue({
      page:
        e.target.getAttribute('data-page-number') == null
          ? 1
          : e.target.getAttribute('data-page-number'),
    });
  }

  dropDownsUpdate(): void {
    if (this.operationsForm.value.client_id.client_id != '-1' && this.operationsForm.value.client_id.client_id != '' && this.operationsForm.value.client_id.client_id != null) {

      this.operationsForm.controls.client_id_id.setValue(this.operationsForm.value.client_id.client_id);
      this.operationsForm.controls.client_id_name.setValue(this.operationsForm.value.client_id.name);


    }

    if (this.operationsForm.value.vendor_id.vendor_id != '-1' && this.operationsForm.value.vendor_id.vendor_id != '' && this.operationsForm.value.vendor_id.vendor_id != null) {

      this.operationsForm.controls.vendor_id_id.setValue(this.operationsForm.value.vendor_id.vendor_id);
      this.operationsForm.controls.vendor_id_name.setValue(this.operationsForm.value.vendor_id.name);
    
    
    }

    if (this.operationsForm.value.project_id.project_id != '-1' && this.operationsForm.value.project_id.project_id != '' && this.operationsForm.value.project_id.project_id != null) {

      this.operationsForm.controls.project_id_id.setValue(this.operationsForm.value.project_id.project_id);
      this.operationsForm.controls.project_id_name.setValue(this.operationsForm.value.project_id.name);


    }

  }

  onFilterApplicationFiles(): void {


    this.dropDownsUpdate();

    this.alertsService.removeAlert();
    this.validate = true;

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    this.loading = true;

    // Project / Sales Invoices
    if (this.type_of_data == 14) {
      this.dataHolderService.updatePageMeta(
        `Filter Invoices | Projects | Page - ${this.operationsForm.value.page}`
      );
    }

    // Project / Sales Estimates
    if (this.type_of_data == 18) {
      this.dataHolderService.updatePageMeta(
        `Filter Estimates | Projects | Page - ${this.operationsForm.value.page}`
      );
    }

      // Vendor Bookings Vouchers
      if (this.type_of_data == 15) {
        this.dataHolderService.updatePageMeta(
          `Filter Vouchers | Vendor Bookings | Page - ${this.operationsForm.value.page}`
        );
      }

    this.alertsService.addAlert('info', 'Filtering Records..');

    this.filterApplicationFilesService.filterApplicationFiles(this.operationsForm.value, this.type_of_data).subscribe(
      (response: any) => {
        this.alertsService.removeAlert();
        this.loading = false;


        if (!response.status) {
          this.alertsService.addAlert("danger", response.message);
          this.totalResults = 0;
          return;
        }

        this.totalResults = response.total_results;
        this.tabularData = response.data.data;
        this.paginationLinks = response.pagination_data;
        this.alertsService.addAlert("success", response.message);

        // console.log(this.tabularData);

      },
      (error) => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      }
    );

  }

  getDownloadUrl(){
    if(this.type_of_data == '14'){
      return '/projects/download-invoices';
    }
    if(this.type_of_data == '18'){
      return '/projects/download-estimates';
    }
    if(this.type_of_data == '15'){
      return '/vendor-bookings/download-vouchers';
    }
  }

  getFieldText(){
    if(this.type_of_data == '14'){
      return 'Invoice';
    }
    if(this.type_of_data == '18'){
      return 'Estimate';
    }
    if(this.type_of_data == '15'){
      return 'Voucher';
    }
  }

  getDueFieldText(){
    if(this.type_of_data == '14'){
      return 'Due';
    }
    if(this.type_of_data == '18'){
      return 'Expiry';
    }
    if(this.type_of_data == '15'){
      return 'Voucher';
    }
  }

}
