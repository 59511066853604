import { DataHolderService } from './data-holder.service';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	queryParams?: any;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	MENUITEMS: Menu[] = [];

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router, private dataHolderService: DataHolderService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}



		// this.setMenuItems();

		// console.log(this.dataHolderService);
	}



	setMenuItems(): any {



		if (!this.dataHolderService.userInfo) {
			return [];
		}


		var menu = [];

		


		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1' ||
			this.dataHolderService.userInfo.is_employee == '1'
		) {
			menu.push(
				{ path: '/dashboard/home', title: 'Dashboard', icon: 'home', type: 'link' },
	
			);

			
		}



		// Accounts Dropdown

		menu.push({
			headTitle1: 'Accounts', headTitle2: 'Manage user accounts in the system',
		});



		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1') {


			menu.push(
				{
					title: 'Clients', type: 'sub', icon: 'dollar-sign', active: false, children: [
						{ path: '/accounts/create', queryParams: { 'is_client': true }, title: 'Add Client', type: 'link' },
						{ path: '/accounts/filter', queryParams: { 'is_client': 'true' }, title: 'View Clients', type: 'link' },
						{ path: '/accounts/filter/custom-field-values/is_client', title: 'Client Attachments & Additional Information', type: 'link' },

					]
				}

			);
		}



		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1' ||
			this.dataHolderService.userInfo.is_employee == '1'
		) {


			menu.push(
				{
					title: 'Vendors', type: 'sub', icon: 'package', active: false, children: [
						{ path: '/accounts/create', queryParams: { 'is_vendor': true }, title: 'Add Vendor', type: 'link' },
						{ path: '/accounts/filter', queryParams: { 'is_vendor': 'true' }, title: 'View Vendors', type: 'link' },
						{ path: '/accounts/filter/custom-field-values/is_vendor', title: 'Vendor Attachments & Additional Information', type: 'link' },

					]
				}

			);
		}



		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1' ||
			this.dataHolderService.userInfo.is_employee == '1'
		) {


			menu.push(
				{
					title: 'Agents', type: 'sub', icon: 'users', active: false, children: [
						{ path: '/accounts/create', queryParams: { 'is_broker': true }, title: 'Add Agent', type: 'link' },
						{ path: '/accounts/filter', queryParams: { 'is_broker': 'true' }, title: 'View Agents', type: 'link' },
						{ path: '/accounts/filter/custom-field-values/is_broker', title: 'Agent Attachments & Additional Information', type: 'link' },

					]
				}

			);
		}


		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1'

		) {


			menu.push(
				{
					title: 'Employees', type: 'sub', icon: 'briefcase', active: false, children: [
						{ path: '/accounts/create', queryParams: { 'is_employee': true }, title: 'Add Employee', type: 'link' },
						{ path: '/accounts/filter', queryParams: { 'is_employee': 'true' }, title: 'View Employees', type: 'link' },
						{ path: '/accounts/filter/custom-field-values/is_employee', title: 'Employee Attachments & Additional Information', type: 'link' },

						{ path: '/accounts/designations/create', title: 'Add Designation', type: 'link' },
						{ path: '/accounts/designations', title: 'View Designations', type: 'link' },
					]
				}

			);
		}


		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1'

		) {


			menu.push(
				{
					title: 'Admins', type: 'sub', icon: 'user-check', active: false, children: [
						{ path: '/accounts/create', queryParams: { 'is_admin': true }, title: 'Add Admin', type: 'link' },
						{ path: '/accounts/filter', queryParams: { 'is_admin': 'true' }, title: 'View Admins', type: 'link' },
						{ path: '/accounts/filter/custom-field-values/is_admin', title: 'Admin Attachments & Additional Information', type: 'link' },

					]
				}

			);
		}


		if (
			this.dataHolderService.userInfo.is_developer == '1'

		) {


			menu.push(
				{
					title: 'Super Admins', type: 'sub', icon: 'user-plus', active: false, children: [
						{ path: '/accounts/create', queryParams: { 'is_super_admin': true }, title: 'Add Super Admin', type: 'link' },
						{ path: '/accounts/filter', queryParams: { 'is_super_admin': 'true' }, title: 'View Super Admins', type: 'link' },
						{ path: '/accounts/filter/custom-field-values/is_super_admin', title: 'Super Admin Attachments & Additional Information', type: 'link' },

					]
				}

			);

			menu.push(
				{
					title: 'All Accounts', type: 'sub', icon: 'users', active: false, children: [
						{ path: '/accounts/filter', title: 'All Accounts', type: 'link' },
					]
				}

			);
		}




		// Projects Dropdown

		menu.push({
			headTitle1: 'Sales', headTitle2: 'Manage Projects',
		});





		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1'

		) {





			// Projects


			menu.push(
				{
					title: 'Projects', type: 'sub', icon: 'package', active: false, children: [

						{ path: '/projects/create', title: 'Add Project', type: 'link' },
						{ path: '/projects/filter', title: 'View Projects', type: 'link' },
					]
				}

			);

			// Project Transactions

			menu.push(
				{
					title: 'Transaction History', type: 'sub', icon: 'plus', active: false, children: [
						{ path: '/projects/filter/transactions', title: 'Project Transaction History', type: 'link' },
					]
				}

			);

			// Project Remainders

			menu.push(
				{
					title: 'Remainders & Tasks', type: 'sub', icon: 'clock', active: false, children: [
						{ path: '/projects/filter/remainders', title: 'Project Remainders', type: 'link' },
					]
				}

			);

			// Project Custom Field Values

			menu.push(
				{
					title: 'Attachments', type: 'sub', icon: 'download-cloud', active: false, children: [
						{ path: '/projects/filter/custom-field-values', title: 'Project Attachments & Additional Information', type: 'link' },
					]
				}

			);

			// Project Invoices & Estimates

			menu.push(
				{
					title: 'Invoices & Estimates', type: 'sub', icon: 'book', active: false, children: [
						{ path: '/projects/filter/invoices', title: 'Project Invoices', type: 'link' },
						{ path: '/projects/filter/estimates', title: 'Project Estimates', type: 'link' },

					]
				}

			);



		}



		// Vendor Bookings / Purchases Dropdown

		menu.push({
			headTitle1: 'Purchases', headTitle2: 'Manage Vendor Bookings & Expenses',
		});


		// vENDOR bOOKINGS ADD EDIT

		menu.push(
			{
				title: 'Vendor Bookings', type: 'sub', icon: 'shopping-bag', active: false, children: [

					{ path: '/vendor-bookings/create', title: 'Add Booking', type: 'link' },
					{ path: '/vendor-bookings/filter', title: 'View Bookings', type: 'link' },
				]
			}

		);


		// Vendor Bookings / Purchases Transactions

		menu.push(
			{
				title: 'Transaction History', type: 'sub', icon: 'minus', active: false, children: [
					{ path: '/vendor-bookings/filter/transactions', title: 'Purchases/Bookings Transaction History', type: 'link' },
				]
			}

		);



		// Vendor Bookings / Purchases Remainders

		menu.push(
			{
				title: 'Remainders & Tasks', type: 'sub', icon: 'clock', active: false, children: [
					{ path: '/vendor-bookings/filter/remainders', title: 'Purchases/Bookings Remainders', type: 'link' },
				]
			}

		);


		// Vendor Bookings / Purchases  Custom Field Values

		menu.push(
			{
				title: 'Attachments', type: 'sub', icon: 'download-cloud', active: false, children: [
					{ path: '/vendor-bookings/filter/custom-field-values', title: 'Vendor Bookings / Purchases Attachments & Additional Information', type: 'link' },
				]
			}

		);



		// Vendor Bookings Vouchers

		menu.push(
			{
				title: 'Vouchers', type: 'sub', icon: 'book', active: false, children: [
					{ path: '/vendor-bookings/filter/vouchers', title: 'Vendor Bookings / Purchases Vouchers', type: 'link' },

				]
			}

		);


		
		// Labour Management  Dropdown

		menu.push({
			headTitle1: 'Man Power', headTitle2: 'Manage Manpower used in projects',
		});


		// Labour Management ADD EDIT

		menu.push(
			{
				title: 'Labour Management', type: 'sub', icon: 'git-branch', active: false, children: [

					{ path: '/labour-management/create', title: 'Add Work', type: 'link' },
					{ path: '/labour-management/filter', title: 'View Works', type: 'link' },
				]
			}

		);






		// Settings Dropdown

		menu.push({
			headTitle1: 'Settings', headTitle2: 'Manage configurations of the system',
		});






		if (
			this.dataHolderService.userInfo.is_developer == '1' ||
			this.dataHolderService.userInfo.is_super_admin == '1' ||
			this.dataHolderService.userInfo.is_admin == '1' ||
			this.dataHolderService.userInfo.is_employee == '1'
		) {


			// Items and Materials



			menu.push(
				{
					title: 'Items / Materials', type: 'sub', icon: 'archive', active: false, children: [

						{ path: '/settings/available-booking-items/create', title: 'Add Item / Material', type: 'link' },
						{ path: '/settings/available-booking-items/filter', title: 'View Items / Materials', type: 'link' },
						{ path: '/settings/available-booking-items', title: 'View Catergorized - Items / Materials', type: 'link' },
					]
				}

			);


			// Inventory

			
			menu.push(
				{
					title: 'Inventory', type: 'sub', icon: 'database', active: false, children: [

						{ path: '/settings/inventory/create', title: 'Add Inventory', type: 'link' },
						{ path: '/settings/inventory/filter', title: 'View Inventory', type: 'link' },
						{ path: '/settings/inventory-logs/filter', title: 'View Inventory Logs', type: 'link' },

					]
				}

			);





			// Labels

			menu.push(
				{
					title: 'Labels', type: 'sub', icon: 'tag', active: false, children: [
						{ path: '/settings/labels/create', title: 'Add Label', type: 'link' },
						{ path: '/settings/labels', title: 'View Labels', type: 'link' },
					]
				}

			);


			// Custom Fields

			menu.push(
				{
					title: 'Custom Fields', type: 'sub', icon: 'hard-drive', active: false, children: [
						{ path: '/settings/custom-fields/create', title: 'Add Custom Field', type: 'link' },
						{ path: '/settings/custom-fields', title: 'View Custom Fields', type: 'link' },
					]
				}

			);


			// Units - Units of Measurements

			menu.push(
				{
					title: 'Unit of measurement', type: 'sub', icon: 'maximize-2', active: false, children: [
						{ path: '/settings/units/create', title: 'Add Unit', type: 'link' },
						{ path: '/settings/units', title: 'View Units', type: 'link' },
					]
				}

			);


			// Tax Units

			menu.push(
				{
					title: 'Tax Units', type: 'sub', icon: 'folder-minus', active: false, children: [
						{ path: '/settings/tax-units/create', title: 'Add Tax Unit', type: 'link' },
						{ path: '/settings/tax-units', title: 'View Tax Units', type: 'link' },
					]
				}

			);

	        // Labourer Types			

			menu.push(
				{
					title: 'Labourer Types', type: 'sub', icon: 'sunrise', active: false, children: [
						{ path: '/settings/labourer-types/create', title: 'Add Labourer Type', type: 'link' },
						{ path: '/settings/labourer-types', title: 'View Labourer Types', type: 'link' },
					]
				}

			);

			// Labour Type of Works			

			  menu.push(
				{
					title: 'Labour Type of Works', type: 'sub', icon: 'zap', active: false, children: [
						{ path: '/settings/labour-type-of-works/create', title: 'Add Labour Type of Work', type: 'link' },
						{ path: '/settings/labour-type-of-works', title: 'View Labour Type of Works', type: 'link' },
					]
				}

			);



			// Settings - Application Configuration

			if (
				this.dataHolderService.userInfo.is_developer == '1'

			) {


				menu.push(
					{
						title: 'Configuration', type: 'sub', icon: 'code', active: false, children: [
							{ path: '/settings/configuration/create', title: 'Add Configuration', type: 'link' },
							{ path: '/settings/configuration', title: 'View Configuration', type: 'link' },
						]
					}

				);




			}

			






		}

			// Settings Dropdown

			menu.push({
				headTitle1: 'General', headTitle2: '',
			});

			if (
				this.dataHolderService.userInfo.is_developer == '1' ||
				this.dataHolderService.userInfo.is_super_admin == '1' ||
				this.dataHolderService.userInfo.is_admin == '1' ||
				this.dataHolderService.userInfo.is_employee == '1'
			) {

				menu.push(
					{
						path: '/accounts/change-password', title: 'Change Password', icon: 'lock', type: 'link'
					}

				);

			}













		return menu;

		// this.MENUITEMS = menu;
		// console.log(this.MENUITEMS);
	}


	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	// MENUITEMS: Menu[] = [
	// {
	// 	headTitle1: 'Accounts', headTitle2: 'Manage users accounts in the system',
	// },
	// {
	// 	title: 'Clients', type: 'sub', icon: 'dollar-sign', active: false, children: [
	// 		{ path: '/accounts/create', queryParams: { 'is_client': true }, title: 'Add Client', type: 'link' },
	// 		{ path: '/accounts/filter', queryParams: { 'accountType': 'is_client' }, title: 'View Clients', type: 'link' },
	// 	]
	// },
	// {
	// 	title: 'Vendors', type: 'sub', icon: 'package', active: false, children: [
	// 		{ path: '/accounts/create', queryParams: { 'is_vendor': true }, title: 'Add Vendor', type: 'link' },
	// 		{ path: '/accounts/filter', queryParams: { 'accountType': 'is_vendor' }, title: 'View Vendors', type: 'link' },
	// 	]
	// },
	// {
	// 	title: 'Agent', type: 'sub', icon: 'users', active: false, children: [
	// 		{ path: '/accounts/create', queryParams: { 'is_broker': true }, title: 'Add Agent', type: 'link' },
	// 		{ path: '/accounts/filter', queryParams: { 'accountType': 'is_broker' }, title: 'View Agents', type: 'link' },
	// 	]
	// },
	// {
	// 	title: 'Employees', type: 'sub', icon: 'briefcase', active: false, children: [
	// 		{ path: '/accounts/create', queryParams: { 'is_employee': true }, title: 'Add Employee', type: 'link' },
	// 		{ path: '/accounts/filter', queryParams: { 'accountType': 'is_employee' }, title: 'View Employees', type: 'link'},
	// 	]
	// },
	// {
	// 	title: 'Designations', type: 'sub', icon: 'briefcase', active: false, children: [
	// 		{ path: '/accounts/designations/create', title: 'Add Designation', type: 'link' },
	// 		{ path: '/accounts/designations', title: 'View Designations', type: 'link'},
	// 	]
	// },

	// ];

	MEGAMENUITEMS: Menu[] = [

	];

	LEVELMENUITEMS: Menu[] = [

	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.setMenuItems());
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
