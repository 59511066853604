import { TransactionRecordsService } from './../services/transaction-records.service';
import { AlertsService } from './../services/alerts.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataHolderService } from '../services/data-holder.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-transaction-records-add-edit',
  templateUrl: './transaction-records-add-edit.component.html',
  styleUrls: ['./transaction-records-add-edit.component.scss']
})
export class TransactionRecordsAddEditComponent implements OnInit {

  
  @Input() isCreate: boolean | string = false;

  @Input() type_of_data: number | string;

  @Input() is_credit : number | string = 1;


  @Input() table_column_id: number | string = '';

  @Output() reloadData = new EventEmitter<any>();

  Object = Object;

  public validate = false;

  public loading = false;

  operationsForm: FormGroup;

  @Input() fetchedData : any = null;

  isCreateSub: any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public transactionRecordsService: TransactionRecordsService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.operationsForm = this.formBuilder.group({
      id: ["-1", Validators.required],
      type_of_data: [this.type_of_data, Validators.required],
      table_column_id: [this.table_column_id, Validators.required],
      title: [''],
      description: [''],
      amount: [0,Validators.required],
      is_credit: [this.is_credit, Validators.required],
      sort_order: ["0", []],
      labourer_type_id : ["-1"],
      labour_type_of_work_id : ["-1"],
      labour_payment_done : ["1"],
      number_of_workers : ["0"],
      payslip_factor_id : ["-1"],
      transaction_date : [""],
      from_date : [""],
      to_date : [""]
      
    });

    if (!this.isCreate) {
      this.updateForm();
    }
  }

  onOperation(): any {

    this.alertsService.removeAlert();
    this.validate = true;
    this.loading = false;

    // console.log(this.operationsForm.invalid);

    if(this.type_of_data == '4'){
      if(this.operationsForm.value.labourer_type_id == '-1'){
          this.alertsService.addAlert("danger", "Please select Labourer Type");
         return;
      }
      if(this.operationsForm.value.labour_type_of_work_id == '-1'){
        this.alertsService.addAlert("danger", "Please Select Labour Type of Work");
       return;
      }
    }

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    this.loading = true;
    const formData = this.operationsForm.value;


    if (this.isCreate) {
      this.alertsService.addAlert('info', 'Adding Data...');
      this.addOperation(formData);
      return;
    }
    else {
      this.alertsService.addAlert('info', 'Updating Data..');
      this.updateOperation(formData);
      return;
    }

  }

  addOperation(formData): void {

    this.transactionRecordsService.addTransactionRecord(formData , this.type_of_data).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);


    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

  updateOperation(formData): void {


    this.transactionRecordsService.updateTransactionRecord(formData , this.type_of_data).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });

  }

  updateForm(response : any = null) {
    if (response != null) {
      this.alertsService.addAlert("success", response.message);
    }

    if(response){
      this.fetchedData = response['data'];

    }


    this.operationsForm.controls.id.setValue(this.fetchedData.id);
    this.operationsForm.controls.type_of_data.setValue(this.fetchedData.type_of_data);
    this.operationsForm.controls.table_column_id.setValue(this.fetchedData.table_column_id);
    this.operationsForm.controls.title.setValue(this.fetchedData.title ? this.fetchedData.title : '');
    this.operationsForm.controls.description.setValue(this.fetchedData.description ? this.fetchedData.description : '');
    this.operationsForm.controls.amount.setValue(this.fetchedData.amount);
    this.operationsForm.controls.is_credit.setValue(this.fetchedData.is_credit);
    this.operationsForm.controls.sort_order.setValue(this.fetchedData.sort_order);

    //  Labour Management
    if(this.type_of_data == '4' ){
      this.operationsForm.controls.labourer_type_id.setValue(this.fetchedData.labourer_type_id);
      this.operationsForm.controls.labour_type_of_work_id.setValue(this.fetchedData.labour_type_of_work_id);
      this.operationsForm.controls.labour_payment_done.setValue(this.fetchedData.labour_payment_done);

      this.operationsForm.controls.number_of_workers.setValue(this.fetchedData.number_of_workers);

    }

    // // Employee Payslip Factors

    // if(this.fetchedData.type_of_data == '17' ){
    //   this.operationsForm.controls.payslip_factor_id.setValue(this.fetchedData.payslip_factor_id);

    // }

    if (this.fetchedData.transaction_date) {
      this.operationsForm.controls.transaction_date.setValue(this.fetchedData.transaction_date);

    }

    if (this.fetchedData.from_date) {
      this.operationsForm.controls.from_date.setValue(this.fetchedData.from_date);

    }

    if (this.fetchedData.to_date) {
      this.operationsForm.controls.to_date.setValue(this.fetchedData.to_date);

    }




  }

  deleteOperation() {
    this.alertsService.addAlert('info', 'Removing Data..');

    this.transactionRecordsService.deleteTransactionRecord(this.fetchedData.id , this.type_of_data).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

      // this.myProfileService.fetchedAccountInfo = response["user"];



    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }


}
