import { CustomFieldValuesService } from './../services/custom-field-values.service';
import { DataHolderService } from './../services/data-holder.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-display-custom-field-values',
  templateUrl: './display-custom-field-values.component.html',
  styleUrls: ['./display-custom-field-values.component.scss']
})
export class DisplayCustomFieldValuesComponent implements OnInit {

  @Input() type_of_data: string | number;

  @Input() table_column_id: string | number;

  fetchedCustomFieldValues: Observable<any>;

  customFieldValuesAddEditFormVisible: boolean = false;

  fetchedCustomFieldValue: Observable<any>;

  count: number = 0;

  Object = Object;

  isCreate: boolean = true;




  constructor(
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    public customFieldValuesService: CustomFieldValuesService,

  ) { }

  ngOnInit(): void {
    this.loadCustomFieldValues();
  }

  loadCustomFieldValues(): void {

    this.customFieldValuesService.fetchCustomFieldValues(this.type_of_data, this.table_column_id).subscribe((response: any) => {
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.fetchedCustomFieldValues = response['data'];
      this.count = response['data'].length;
      this.customFieldValuesAddEditFormVisible = false;

    },
      error => {
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
      });

  }

  fetchValueAndDisplayForm(id): void {

    this.alertsService.addAlert('info', 'Fetching Data..');

    this.customFieldValuesService.fetchCustomFieldValueById(id).subscribe((response: any) => {
      this.alertsService.removeAlert();
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.fetchedCustomFieldValue = response['data'];
      this.isCreate = false;
      this.customFieldValuesAddEditFormVisible = true;

    },
      error => {
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
      });



  }

  reloadCustomFieldValues(data): void {
    // console.log(data);
    this.alertsService.addAlert('info', 'Fetching Latest Data..');
    this.loadCustomFieldValues();
    this.alertsService.removeAlert();

  }

}
