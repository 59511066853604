<ng-container [formGroup]="agentForm">
    <div class="col-form-label"> <label for="agent_id"> Agent ID : </label> </div>
    <ng-select name="agent_id" class="form-control form-control-info ng-select-outline" #select [items]="fetchedItems$ | async" bindLabel="name"
        bindValue="id" [virtualScroll]="true" [trackByFn]="trackByFn" [loading]="isLoading"
        typeToSearchText="Please enter 1 or more characters" [typeahead]="searchInput$" formControlName="agent_id"
        [(ngModel)]="idForModel">

        <ng-template ng-option-tmp let-item="item" let-index="index">
            <!-- -->
            <p> <img class="m-2 rounded-circle" height="25" width="25"
                    src="
                {{ (item.profile_picture_url &&  item.profile_picture_url.length > 5) ? (dataHolderService.apiBaseUrl + '/auth/get-profile-photo/' + item.id ) : '/assets/images/dummy.png'}}" />
                <b>{{item.name}}</b> <span class="m-2"> ( ID : {{ item.id }} ) </span></p>
        </ng-template>

    </ng-select>

    <p *ngIf="agentForm.value.agent_id && agentForm.value.agent_id!='-1'">
        <small>Agent ID : <a target="_blanc" [routerLink]="[ '/accounts/edit/' , agentForm.value.agent_id ]" class="li">
                {{ agentForm.value.agent_id }} </a>
        </small>
    </p>


</ng-container>