<ng-container>


    <div class="card display-container-card">
        <div class="card-header card-header-bg display-container-card-bg">
            <p class="card-header-heading"> Add Additional Information and Attachments </p>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                    <!-- <table class="table table-striped table-responsive mt-5 table card-table table-vcenter text-nowrap"> -->
                    <thead>
                        <tr>
                            <th colspan="3" class="" scope="col">
                                <a (click)="isCreate = true; customFieldValuesAddEditFormVisible = true;"
                                    class="btn btn-primary btn-sm">
                                    <i class="fa fa-plus"></i> ADDITIONAL INFORMATION </a>
                            </th>

                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <!-- <th class="" scope="col"> </th> -->
                            <th (click)="loadCustomFieldValues()" class="reloadCustomFieldValues" scope="col">
                                <a class="link-dark"> <i class="fa fa-rotate-right"></i> Refresh </a>
                            </th>
                            <!-- <th class="" scope="col"> </th> -->

                        </tr>
                        <tr>
                            <th class="bg-primary" scope="col"></th>
                            <th class="bg-primary" scope="col">ID</th>
                            <th class="bg-primary" scope="col">Key</th>
                            <th class="bg-primary" scope="col">Value</th>
                            <th class="bg-primary" scope="col">Attachment</th>
                            <!-- <th class="bg-primary" scope="col">Description</th> -->
                            <th class="bg-primary" scope="col">Timestamps</th>
                            <!-- <th class="bg-primary" scope="col">Last Update</th> -->



                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="count < 1">
                            <td>No Records Found</td>
                        </tr>

                        <ng-container *ngIf="fetchedCustomFieldValues && count > 0">



                            <tr *ngFor="let key of Object.keys(fetchedCustomFieldValues)">
                                <td>
                                    <button (click)="fetchValueAndDisplayForm(fetchedCustomFieldValues[key].id)"
                                        class="btn btn-secondary btn-sm">
                                        <i class="fa fa-pencil-square-o"></i> Edit </button>

                                </td>
                                <td>{{ fetchedCustomFieldValues[key].id }}</td>
                                <td>{{ fetchedCustomFieldValues[key].custom_field.name  }}</td>
                                <td>{{ fetchedCustomFieldValues[key].value  }}</td>
                                <td>

                                    <p> 

                                        <a *ngIf="fetchedCustomFieldValues[key].attachment_url && fetchedCustomFieldValues[key].attachment_url.length > 5"
                                            download
                                            href="{{ dataHolderService.apiBaseUrl + '/shared/get-custom-field-value-attachment/' + fetchedCustomFieldValues[key].id }}">
                                           <small> {{ fetchedCustomFieldValues[key].client_side_name ? fetchedCustomFieldValues[key].client_side_name : ""  }} </small>
                                            <br>
                                            <i class="fa fa-download"></i> Download</a>

                                    </p>

                                 


                                </td>
                                <!-- <td>{{ fetchedCustomFieldValues[key].description  }}</td> -->


                                <td>

                                    <p>
                                        <small>
                                            Created At :
                                            <!-- <small>{{ fetchedCustomFieldValues[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->

                                            <small> {{ fetchedCustomFieldValues[key].created_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedCustomFieldValues[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>

                                    <p>
                                        <small>

                                            Updated At :

                                            <!-- <small>{{ fetchedCustomFieldValues[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->
                                            <small> {{ fetchedCustomFieldValues[key].updated_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedCustomFieldValues[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>
                                </td>

                            </tr>

                        </ng-container>





                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <section class="fullScreenPopup" *ngIf="customFieldValuesAddEditFormVisible">
        <div class=" text-right mt-1 mb-1" (click)="customFieldValuesAddEditFormVisible = false; ">
            <button class="btn btn-primary"> <i class="fa fa-arrow-circle-o-left mr-2"></i> CANCEL</button>
        </div>


        <app-custom-field-values-add-edit [isCreate]="isCreate" 
            [type_of_data]="type_of_data"
            [table_column_id]="table_column_id"
            [fetchedCustomFieldValue]="fetchedCustomFieldValue" (reloadData)="reloadCustomFieldValues($event)">
        </app-custom-field-values-add-edit>




    </section>



</ng-container>