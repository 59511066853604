import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { DataHolderService } from 'src/app/shared/services/data-holder.service';


@Injectable({
  providedIn: 'root'
})
export class VendorBookedItemsService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }


  fetchBookedItems(type_of_data, table_column_id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/vendor-bookings/booked-items-of-a-booking/${type_of_data}/${table_column_id}`);

  }


  fetchBookedItemById(id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/vendor-bookings/get-booked-item-info/${id}`);

  }

  addBookedItem(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/vendor-bookings/add-booked-item', body);

  }

  updateBookedItem(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/vendor-bookings/update-booked-item', body);

  }

  deleteBookedItem(id , update_inventory , item_order_date) {
    let body = new HttpParams();
    body = body.set('id', id);
    body = body.set('update_inventory', update_inventory);
    body = body.set('item_order_date', item_order_date);

    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/vendor-bookings/delete-booked-item', {
      params: body
    });

  }
}
