import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianNumberFormat'
})
export class IndianNumberFormatPipe implements PipeTransform {

  transform(value: number): any {
    return new Intl.NumberFormat('en-IN', { style: "currency", currency: "INR" }).format(value) + ' ';

  }

}
