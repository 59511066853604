<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      

       
      <p class="mb-0"> © All Rights Reserved   </p>
      <p class="mb-0"> BERP : A Product of Blindersoe (www.blindersoe.com)  </p>

    </div>
  </div>
</div>