import { MaterialUsedService } from './../services/material-used.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertsService } from '../services/alerts.service';
import { DataHolderService } from '../services/data-holder.service';



@Component({
  selector: 'app-material-used-add-edit',
  templateUrl: './material-used-add-edit.component.html',
  styleUrls: ['./material-used-add-edit.component.scss']
})
export class MaterialUsedAddEditComponent implements OnInit {


  @Input() isCreate: boolean | string = false;

  @Input() table_column_id: number | string;

  @Input() type_of_data: number | string;


  @Output() reloadData = new EventEmitter<any>();

  Object = Object;

  public validate = false;

  public loading = false;

  operationsForm: FormGroup;

  @Input() fetchedData: any = null;

  isCreateSub: any;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public materialUsedService: MaterialUsedService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.operationsForm = this.formBuilder.group({
      id: ["-1", Validators.required],
      type_of_data: [this.type_of_data, Validators.required],
      table_column_id: [this.table_column_id, Validators.required],
      title: [''],
      item_id: ['-1'],
      unit_id: ['-1'],
      quantity: ['1', Validators.required],
      details: [''],
      transaction_date: [""],

    });

    if (!this.isCreate) {
      this.updateForm();
    }
  }

  onOperation(): any {

    this.alertsService.removeAlert();
    this.validate = true;
    this.loading = false;

    // console.log(this.operationsForm.invalid);

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    if (this.operationsForm.value.unit_id == '-1') {
      this.alertsService.addAlert("danger", "Please Select Unit of measurement");
      return;
    }

    if (this.operationsForm.value.quantity == '') {
      this.alertsService.addAlert("danger", "No. of Units can't be empty");
      return;
    }

    if (this.operationsForm.value.item_id == '-1') {
      this.alertsService.addAlert("danger", "Please select Item / Material");
      return;
    }

    this.loading = true;
    const formData = this.operationsForm.value;


    if (this.isCreate) {
      this.alertsService.addAlert('info', 'Adding Item / Material Usage...');
      this.addOperation(formData);
      return;
    }
    else {
      this.alertsService.addAlert('info', 'Updating Item / Material Usage');
      this.updateOperation(formData);
      return;
    }

  }

  addOperation(formData): void {

    this.materialUsedService.addMaterialUsed(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);


    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

  updateOperation(formData): void {


    this.materialUsedService.updateMaterialUsed(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });

  }

  updateForm(response: any = null) {
    if (response != null) {
      this.alertsService.addAlert("success", response.message);
    }

    if (response) {
      this.fetchedData = response['data'];

    }


    this.operationsForm.controls.id.setValue(this.fetchedData.id);
    this.operationsForm.controls.table_column_id.setValue(this.fetchedData.table_column_id);
    this.operationsForm.controls.type_of_data.setValue(this.fetchedData.type_of_data);

    this.operationsForm.controls.title.setValue(this.fetchedData.title ? this.fetchedData.title : '');
    this.operationsForm.controls.details.setValue(this.fetchedData.details ? this.fetchedData.details : '');
    this.operationsForm.controls.item_id.setValue(this.fetchedData.item_id);
    this.operationsForm.controls.unit_id.setValue(this.fetchedData.unit_id);
    this.operationsForm.controls.quantity.setValue(this.fetchedData.quantity);
    if (this.fetchedData.transaction_date) {
      this.operationsForm.controls.transaction_date.setValue(this.fetchedData.transaction_date);

    }


  }






  deleteOperation() {
    this.alertsService.addAlert('info', 'Removing Data..');

    this.materialUsedService.deleteMaterialUsed(this.fetchedData.id ).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

      // this.myProfileService.fetchedAccountInfo = response["user"];



    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



    // this.materialUsedService.deleteTransactionRecord(this.fetchedData.id).subscribe((response: any) => {
    //   this.alertsService.removeAlert();
    //   this.loading = false;
    //   if (!response.status) {
    //     this.alertsService.addAlert("danger", response.message);
    //     return;
    //   }
    //   this.alertsService.addAlert("success", response.message);
    //   this.validate = false;
    //   this.reloadData.emit(true);

    //   // this.myProfileService.fetchedAccountInfo = response["user"];



    // },
    //   error => {
    //     this.loading = false;
    //     this.alertsService.addAlert("danger", "Server Error, Contact Admin");
    //     this.validate = false;
    //   });



  }


}
