import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullWithDefault'
})
export class NullWithDefaultPipe implements PipeTransform {

  transform(value: any, defaultText: string = ''): any {

    if (value === null || value == 'null' ) {
      return defaultText;
    }

    return value;

  }

}
