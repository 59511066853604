import { AlertsService } from './../services/alerts.service';
import { DataHolderService } from './../services/data-holder.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxUnitValuesService } from '../services/tax-unit-values.service';


@Component({
  selector: 'app-display-tax-unit-values',
  templateUrl: './display-tax-unit-values.component.html',
  styleUrls: ['./display-tax-unit-values.component.scss']
})
export class DisplayTaxUnitValuesComponent implements OnInit {

  @Input() type_of_data: string | number;

  @Input() table_column_id: string | number;

  @Input() basePrice : number = 0;

  @Input() totalTax : number = 0;



  fetchedAllData : Observable<any>;

  addEditFormVisible : boolean = false;


  fetchedValue: Observable<any>;

  
  count: number = 0;

  Object = Object;

  isCreate: boolean = true;


  constructor(
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    public taxUnitValuesService: TaxUnitValuesService,
  ) { }

  ngOnInit(): void {
    this.loadAllData();
  }

  loadAllData(): void {

    this.taxUnitValuesService.fetchTaxUnitValues(this.type_of_data, this.table_column_id).subscribe((response: any) => {
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.fetchedAllData = response['data'];
      this.count = response['data'].length;
      this.addEditFormVisible = false;

    },
      error => {
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
      });

  }

  fetchValueAndDisplayForm(id): void {

    this.alertsService.addAlert('info', 'Fetching Data..');

    this.taxUnitValuesService.fetchTaxUnitValueById(id).subscribe((response: any) => {
      this.alertsService.removeAlert();
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.fetchedValue = response['data'];
      this.isCreate = false;
      this.addEditFormVisible = true;

    },
      error => {
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
      });



  }

  refreshOnlyChildData() : void{
    this.loadAllData();
  }


  // Reloads data of the Parents
  // Parent will trigger reload of all children

  reloadData(data: any = null): void {
  
    var ele = document.getElementById("refreshDataOfTheParent");
    if(ele){
      ele.click();
    }

  }


}
