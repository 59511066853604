import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TaxUnitValuesService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  fetchTaxUnitValues(type_of_data, table_column_id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-tax-unit-values/${type_of_data}/${table_column_id}`);

  }


  fetchTaxUnitValueById(id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/shared/get-tax-unit-value-info/${id}`);

  }

  addTaxUnitValue(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/add-tax-unit-value', body);

  }

  updateTaxUnitValue(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/shared/update-tax-unit-value', body);

  }


  deleteTaxUnitValue(id , type_of_data) {
    let body = new HttpParams();
    body = body.set('id', id);
    body = body.set('type_of_data', type_of_data);

    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/shared/delete-tax-unit-value', {
      params: body
    });

  }

}
