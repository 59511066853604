import { LabelValuesService } from './../services/label-values.service';
import { DataHolderService } from './../services/data-holder.service';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { Component, OnInit, Input ,  Output } from '@angular/core';

@Component({
  selector: 'app-autocomplete-labels',
  templateUrl: './autocomplete-labels.component.html',
  styleUrls: ['./autocomplete-labels.component.scss']
})
export class AutocompleteLabelsComponent implements OnInit {

  @Input() type_of_data: string | number | any;

  @Input() table_column_id: string | number | any;

  @Input() pre_selected_labels : any ;

  public validate = false;

  public loading = false;

  selectedLabels = [];

  constructor(
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    public labelValuesService: LabelValuesService,

  ) { }

  ngOnInit(): void {
    // console.log(this.pre_selected_labels);
    this.setPreSelectedValues();
  
  }

  setPreSelectedValues(){


    let arr = [];
    if(this.pre_selected_labels && this.pre_selected_labels.length > 0){
      
      for(let key in this.pre_selected_labels){
        arr.push(this.pre_selected_labels[key].label_id); 
      }
    }
    // console.log(arr);

    this.selectedLabels = [...new Set(arr)];

  }


  onOperation(): any {

    this.alertsService.addAlert('info', 'Adding Labels..');


    let payLoad = {
      type_of_data : this.type_of_data , 
      table_column_id : this.table_column_id , 
      selectedLabels : this.selectedLabels , 

    };

    this.labelValuesService.manageLabels( this.type_of_data , this.table_column_id , JSON.stringify(payLoad) ).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;

      this.pre_selected_labels = response.data;
      this.setPreSelectedValues();
     


    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });

   

  }

  reloadData(data: any = null): void {

    this.setPreSelectedValues();
  
    var ele = document.getElementById("refreshDataOfTheParent");
    if(ele){
      ele.click();
    }

  }

}
