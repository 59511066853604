import { DataHolderService } from './../../shared/services/data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  fetchedAccountInfo: any;

  fetchedDesignations: any;

  fetchedDesignation: any;

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  addAcount(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/add-account', body);

  }


  updateAcount(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/update-account', body);

  }

  deleteAccount(accountId): any {
    let body = new HttpParams();
    body = body.set('accountId', accountId);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/auth/delete-account', {
      params: body
    });
  }

  filterAccounts(body): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/auth/filter-users', {
      params: body
    });
  }


  deleteAccountPicture(accountId) {
    let body = new HttpParams();
    body = body.set('accountId', accountId);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/auth/delete-profile-photo', {
      params: body
    });

  }




  fetchAccount(accountId): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/auth/get-user-info/${accountId}`);

  }



  loadClientsAutofill(term) {
    let body = new HttpParams();
    body = body.set('term', term);
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + '/auth/client-search-auto-fill', {
      params: body
    });

  }


  fetchDesignation(id): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/auth/get-designation-info/${id}`);

  }

  fetchDesignations(): any {
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/auth/designations`);

  }

  addDesignation(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/add-designation', body);

  }

  updateDesignation(body): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/update-designation', body);

  }

  deleteDesignation(designationId): any {
    let body = new HttpParams();
    body = body.set('designationId', designationId);
    return this.httpClient.delete(this.dataHolderService.apiBaseUrl + '/auth/delete-designation', {
      params: body
    });
  }


  changePassword(body){
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/change-password', body);

  }






}
