<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onFilterCustomFieldValues()" id="formWithData">

                        <div class="form-row">
                            <div class="col-md-12">
                                <ng-container *ngIf="type_of_data == '2'">
                                    <h2>Sales / Projects - Attachments </h2>
                                </ng-container>

                                <ng-container *ngIf="type_of_data == '1'">
                                    <h2>Account Attachments</h2>
                                </ng-container>

                                <ng-container *ngIf="type_of_data == '3'">
                                    <h2>Vendor Bookings / Purchases Attachments</h2>
                                </ng-container>

                                <p>Custom Data and Additional Information</p>

                                <input type="hidden" formControlName="page" name="page">
                            </div>
                        </div>

                        <div class="form-row mt-2 mb-4">

                            <div class="col-md-6">
                                <button type="button" (click)="isFormVisible = !isFormVisible"
                                    class="btn btn-info-gradien  m-1"> <i class="fa fa-filter"></i>
                                    {{ isFormVisible ? 'Hide ': 'Show' }} Filters </button>




                                <button *ngIf="isFormVisible" (click)="operationsForm.controls.page.setValue('1')"
                                    type="submit" class="btn btn-info-gradien  m-1" [class.loader--text]="loading"
                                    [disabled]="loading" type="submit">
                                    <i class="fa fa-check"></i>
                                    <span>{{ loading ? '' : ( ' Apply Filters'  ) }}</span></button>
                            </div>

                            <div class="col-md-3">

                                <!-- <label for="report_type">Select Report Type</label> -->

                                <select class="form-control" placeholder="Select Report Type.."
                                    formControlName="report_type" name="report_type" id="report_type">
                                    <option value="csv">CSV ( Excel )</option>
                                    <option value="pdf">PDF</option>
                                    <option value="html">View HTML</option>
                                    <!-- <option value="xlsx">Spreadsheet</option> -->


                                </select>


                            </div>

                            <div class="col-md-3">
                                <a type="button" (click)="dropDownsUpdate()" target="_blanc"
                                    [routerLink]="dataHolderService.apiBaseUrl + getDownloadUrl()"
                                    [queryParams]="{
                            id : operationsForm.value.id,
                            client_id_id : operationsForm.value.client_id_id,
                            client_id_name : operationsForm.value.client_id_name,
    
                            project_id_id : operationsForm.value.project_id_id,
                            project_id_name : operationsForm.value.project_id_name,

                            vendor_id_id : operationsForm.value.vendor_id_id,
                            vendor_id_name : operationsForm.value.vendor_id_name,

                            booking_id : operationsForm.value.booking_id,

    
                            value : operationsForm.value.value,
                            description : operationsForm.value.description,
                            custom_field_id : operationsForm.value.custom_field_id,

                            account_filter_type : operationsForm.value.account_filter_type,
                            account_id : operationsForm.value.account_id,

    
                        
    
                          
                            pagination : operationsForm.value.pagination,
                            sort_by : operationsForm.value.sort_by,
                            sort_order : operationsForm.value.sort_order,
                            report_type :  operationsForm.value.report_type
    
    
    
                        }" class="btn btn-info-gradien m-1"> <i class="fa fa-download"></i> Export Records -
                                    {{ operationsForm.value.report_type |  uppercase  }}</a>
                            </div>

                        </div>

                        <div class="form-row mt-2 mb-2">



                            <div class="col-md-4">
                                <label for="sort_by">Sort by</label>

                                <select data-page-number="1"
                                    (change)="updatePageNumber($event); onFilterCustomFieldValues();"
                                    class="form-control" placeholder="Sort by.." formControlName="sort_by"
                                    name="sort_by" id="sort_by">
                                    <option value="id">ID</option>
                                    <option value="created_at">Created At</option>
                                    <option value="updated_at">Updated At</option>
                                    <option value="value">Value</option>

                                </select>
                            </div>


                            <div class="col-md-4">
                                <label for="sort_order">Sort Order</label>

                                <select data-page-number="1"
                                    (change)="updatePageNumber($event); onFilterCustomFieldValues();"
                                    class="form-control" placeholder="Sort Order.." formControlName="sort_order"
                                    name="sort_order" id="sort_order">
                                    <option value="ASC">Low to High</option>
                                    <option value="DESC">High to Low</option>


                                </select>
                            </div>

                            <div class="col-md-4">
                                <label for="pagination">Results per page</label>

                                <select data-page-number="1"
                                    (change)="updatePageNumber($event); onFilterCustomFieldValues();"
                                    class="form-control" placeholder="Pagination.." formControlName="pagination"
                                    name="pagination" id="pagination">

                                    <option *ngFor="let l of dataHolderService.paginationOptions" [value]="l">
                                        {{ l }}
                                    </option>

                                </select>
                            </div>








                        </div>

                        <div [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">

                            <div class="form-row mt-5">
                                <input type="hidden" formControlName="client_id_id" name="client_id_id">
                                <input type="hidden" formControlName="client_id_name" name="client_id_name">
                                <input type="hidden" formControlName="project_id_id" name="project_id_id">
                                <input type="hidden" formControlName="project_id_name" name="project_id_name">
                                <input type="hidden" formControlName="account_filter_type" name="account_filter_type">
                                <input type="hidden" formControlName="vendor_id_id" name="vendor_id_id">
                                <input type="hidden" formControlName="vendor_id_name" name="vendor_id_name">

                                <div class="col-md-6 mb-3">
                                    <label for="id">Attachment ID : </label>
                                    <input name="id" class="form-control" id="id" type="text"
                                        placeholder="Attachment ID " formControlName="id">
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="custom_field_id ">Custom Field : </label>

                                    <select class="form-control" placeholder="Custom Field.."
                                        formControlName="custom_field_id" name="custom_field_id" id="custom_field_id">

                                        <option [value]="-1"> All </option>
                                        <option *ngFor="let key of Object.keys(dataHolderService.customFields)"
                                            [value]="dataHolderService.customFields[key].id">
                                            {{ dataHolderService.customFields[key].name }}
                                        </option>

                                    </select>


                                </div>



                            </div>

                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="value"> Value : </label>
                                    <input class="form-control" id="value" formControlName="value" name="value" />
                                    <div class="form-error-message">

                                    </div>

                                </div>


                                <div class="col-md-6 mb-3">
                                    <label for="description"> Description : </label>
                                    <textarea class="form-control" id="description" rows="3"
                                        formControlName="description" name="description"></textarea>
                                </div>
                            </div>


                        
                            <!--
                                Accounts Custom Field Values
                            -->


                            <ng-container *ngIf="type_of_data == '1'">

                                <div class="col-md-6 mb-3">
                                    <label for="account_id">Account ID : </label>
                                    <input name="account_id" class="form-control" id="account_id" type="text"
                                        placeholder="Account ID " formControlName="account_id">
                                </div>

                            </ng-container>

                            <!--
                                Project Sales Custom Field Values
                            -->
                            <ng-container *ngIf="type_of_data == '2'">

                                <div class="form-row">

                                    <div class="col-md-12 mb-3">
                                        <app-autocomplete-clients formControlName="client_id"
                                            [preSelectedValue]="preselectedClientValue"
                                            [hasPreSelectedValue]="hasPreSelectedClientValue">
                                        </app-autocomplete-clients>

                                        <div class="form-error-message">

                                        </div>

                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <app-autocomplete-projects formControlName="project_id"
                                            [preSelectedValue]="preselectedProjectValue"
                                            [hasPreSelectedValue]="hasPreSelectedProjectValue">
                                        </app-autocomplete-projects>

                                        <div class="form-error-message">

                                        </div>

                                    </div>

                                </div>

                            </ng-container>

                             <!--
                            Vendor Bookings Attachments Filters
                        -->
                        <ng-container *ngIf="type_of_data == '3'">

                            <div class="form-row">

                                <div class="col-md-4 mb-3">
                                    <label for="booking_id">Purchase ID (PO No. ) : </label>
                                    <input name="booking_id" class="form-control" id="booking_id" type="text"
                                        placeholder="Purchase ID (PO No.)" formControlName="booking_id">
                                </div>

                                <div class="col-md-12 mb-3">
                                    <app-autocomplete-vendors formControlName="vendor_id"
                                        [preSelectedValue]="preselectedVendorValue"
                                        [hasPreSelectedValue]="hasPreSelectedVendorValue">
                                    </app-autocomplete-vendors>

                                    <div class="form-error-message">

                                    </div>

                                </div>

                                <div class="col-md-12 mb-3">
                                    <app-autocomplete-projects formControlName="project_id"
                                        [preSelectedValue]="preselectedProjectValue"
                                        [hasPreSelectedValue]="hasPreSelectedProjectValue">
                                    </app-autocomplete-projects>

                                    <div class="form-error-message">

                                    </div>

                                </div>

                            </div>

                        </ng-container>

                            <br><br>

                            <button (click)="operationsForm.controls.page.setValue('1')" type="submit"
                                class="btn btn-pill btn-air-primary btn-primary m-2" [class.loader--text]="loading"
                                [disabled]="loading" type="submit">
                                <span>{{ loading ? '' : ( ' APPLY FILTERS ' ) }}</span></button>


                        </div>

                    </form>
                </div>

                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table card-table table-vcenter text-nowrap">

                            <thead>
                                <tr>
                                    <th class="bg-primary" scope="col"></th>
                                    <th class="bg-primary" scope="col">ID</th>
                                    <th class="bg-primary" scope="col">Key</th>
                                    <th class="bg-primary" scope="col">Value</th>
                                    <th class="bg-primary" scope="col">Attachment </th>


                                    <ng-container *ngIf="type_of_data == '2'">
                                        <th class="bg-primary" scope="col">Client </th>
                                        <th class="bg-primary" scope="col">Project</th>
                                    </ng-container>

                                

                                    <ng-container *ngIf="type_of_data == '1'">
                                        <th class="bg-primary" scope="col">Account</th>
                                    </ng-container>

                                    <ng-container *ngIf="type_of_data == '3'">

                                        <th class="bg-primary" scope="col">Vendor</th>
                                        <th class="bg-primary" scope="col">Purchase</th>
                                        <th class="bg-primary" scope="col">Project</th>


                                    </ng-container>

                                      

                                    <th class="bg-primary" scope="col">Created At </th>
                                    <th class="bg-primary" scope="col">Last Update </th>

                                </tr>


                            </thead>

                            <tbody *ngIf="totalResults > 0">

                                <tr *ngFor="let key of Object.keys(tabularData)">


                                    <td> 

                                        <ng-container *ngIf="type_of_data == '2'">

                                            <a class=""
                                                    [routerLink]="[ '/projects/edit/' , tabularData[key].table_column_id ]">
                                                    <i class="fa fa-pencil-square-o"></i> Edit </a>
    
                                        </ng-container>

                                        <ng-container *ngIf="type_of_data == '1'">

                                            <a class=""
                                                    [routerLink]="[ '/accounts/edit/' , tabularData[key].table_column_id ]">
                                                    <i class="fa fa-pencil-square-o"></i> Edit </a>
    
                                        </ng-container>

                                        <ng-container *ngIf="type_of_data == '3'">

                                            <a class=""
                                                    [routerLink]="[ '/vendor-bookings/edit/' , tabularData[key].table_column_id ]">
                                                    <i class="fa fa-pencil-square-o"></i> Edit </a>
    
                                        </ng-container>


                                    </td>

                                    


                                   

                                    <td> <b> {{ tabularData[key].id  }} </b> </td>

                                    <td>{{ tabularData[key].custom_field.name   }}</td>

                                    <td> {{ tabularData[key].value   }}</td>

                                    <td>

                                        <p>

                                            <a *ngIf="tabularData[key].attachment_url && tabularData[key].attachment_url.length > 5"
                                                download
                                                href="{{ dataHolderService.apiBaseUrl + '/shared/get-custom-field-value-attachment/' + tabularData[key].id }}">
                                                <small>
                                                    {{ tabularData[key].client_side_name ? tabularData[key].client_side_name : ""  }}
                                                </small>
                                                <br>
                                                <i class="fa fa-download"></i> Download</a>

                                        </p>




                                    </td>



                                    <ng-container *ngIf="type_of_data == '2'">

                                        <td>
                                            <small>

                                                {{ tabularData[key].project.client.name }}<br>
                                                Client ID : {{ tabularData[key].project.client.id }}<br>
                                                {{ tabularData[key].project.client.email | nullWithDefault }}<br>
                                                {{ tabularData[key].project.client.mobile_number | nullWithDefault }}
                                                <br>
                                            </small>


                                        </td>

                                        <td>

                                            <small>

                                                {{ tabularData[key].project.name }} <br>
                                                Project ID : {{ tabularData[key].project.id }} <br>

                                                Project Status :
                                                <span class="badge badge-success"
                                                    *ngIf="tabularData[key].project.status == '2'">Completed</span>
                                                <span class="badge badge-dark"
                                                    *ngIf="tabularData[key].project.status == '0'">On
                                                    Going</span>
                                                <span class="badge badge-primary"
                                                    *ngIf="tabularData[key].project.status== '1'">Upcoming</span>
                                                <span class="badge badge-warning"
                                                    *ngIf="tabularData[key].project.status == '4'">Planning /
                                                    Estimation</span>
                                                <span class="badge badge-info"
                                                    *ngIf="tabularData[key].project.status  == '5'">Quotation</span>

                                                <br>


                                                Order Price :
                                                {{ ( tabularData[key].project.calculated_total_order_price ) | indianNumberFormat }}
                                                <br>
                                                Payment Received :
                                                {{  tabularData[key].project.calculated_amount_paid_till_now  | indianNumberFormat }}
                                                <br>
                                                Payment Pending :
                                                {{ ( (tabularData[key].project.calculated_total_order_price) - tabularData[key].project.calculated_amount_paid_till_now  ) | indianNumberFormat }}
                                                <br>

                                            </small>

                                        </td>

                                    </ng-container>

                                    <ng-container *ngIf="type_of_data == '1'">

                                        <td>
                                            <p>Account ID : {{ tabularData[key].user.id }}</p>
                                          
                                            <p>{{ tabularData[key].user.name }}</p>
                                            <p>{{ tabularData[key].user.email | nullWithDefault }}</p>
                                            <p>{{ tabularData[key].user.mobile_number | nullWithDefault }}
                                            </p>

                                            <p> 

                                                <span class="badge badge-success"
                                                *ngIf="tabularData[key].user.is_client == '1'">Client</span>
                                            <span class="badge badge-dark"
                                                *ngIf="tabularData[key].user.is_broker == '1'">Agent</span>
                                            <span class="badge badge-primary"
                                                *ngIf="tabularData[key].user.is_vendor== '1'">Vendor</span>
                                            <span class="badge badge-warning"
                                                *ngIf="tabularData[key].user.is_employee == '1'">Employee</span>
                                            <span class="badge badge-info"
                                                *ngIf="tabularData[key].user.is_admin  == '1'">Admin</span>
                                            <span class="badge badge-secondary"
                                                *ngIf="tabularData[key].user.is_super_admin  == '1'">Super Admin</span>
                                            <span class="badge badge-golden-rod"
                                                *ngIf="tabularData[key].user.is_developer  == '1'">Application
                                                Developer</span>


                                            </p>

                                        </td>

                                        

                                    </ng-container>

                                    <ng-container *ngIf="type_of_data == '3'">

                                        <td>

                                            <small>

                                                {{ tabularData[key].vendor_booking.vendor.name }} <br>
                                                Vendor ID : {{ tabularData[key].vendor_booking.vendor.id }} <br>
                                                {{ tabularData[key].vendor_booking.vendor.email | nullWithDefault }}
                                                <br>
                                                {{ tabularData[key].vendor_booking.vendor.mobile_number | nullWithDefault }}
                                                <br>

                                            </small>

                                        </td>

                                        <td>

                                            <small>
                                                {{ tabularData[key].vendor_booking.name | nullWithDefault }} <br>
                                                Purchase ID (PO No.) : {{ tabularData[key].vendor_booking.id }} <br>

                                                Booking Status :
                                                <span class="badge badge-success"
                                                    *ngIf="tabularData[key].vendor_booking.status == '2'">Completed</span>
                                                <span class="badge badge-dark"
                                                    *ngIf="tabularData[key].vendor_booking.status == '0'">On
                                                    Going</span>
                                                <span class="badge badge-primary"
                                                    *ngIf="tabularData[key].vendor_booking.status== '1'">Upcoming</span>
                                                <span class="badge badge-warning"
                                                    *ngIf="tabularData[key].vendor_booking.status == '4'">Planning /
                                                    Estimation</span>
                                                <span class="badge badge-info"
                                                    *ngIf="tabularData[key].vendor_booking.status  == '5'">Quotation</span>
                                                <br>




                                                Order Price :
                                                {{ ( tabularData[key].vendor_booking.calculated_total_order_price  ) | indianNumberFormat }}
                                                <br>
                                                Payment Done :
                                                {{  tabularData[key].vendor_booking.calculated_amount_paid_till_now  | indianNumberFormat }}
                                                <br>

                                                <ng-container
                                                    *ngIf="( (tabularData[key].vendor_booking.calculated_total_order_price) == tabularData[key].vendor_booking.calculated_amount_paid_till_now  )">

                                                    <span style="color: green !important;">FULL PAYMENT DONE</span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="( (tabularData[key].vendor_booking.calculated_total_order_price) != tabularData[key].vendor_booking.calculated_amount_paid_till_now  )">

                                                    <span style="color: red !important;">PENDING PAYMENT <br>
                                                        {{ ( tabularData[key].vendor_booking.calculated_total_order_price - tabularData[key].vendor_booking.calculated_amount_paid_till_now ) | indianNumberFormat }}
                                                    </span>
                                                </ng-container>

                                            </small>


                                        </td>

                                        <td>
                                            <ng-container *ngIf="tabularData[key].vendor_booking.project_id">

                                                <small>
                                                    <b>Project : </b> <br />

                                                    {{ tabularData[key].vendor_booking.project.name }} <br />
                                                    Project ID :
                                                    {{ tabularData[key].vendor_booking.project.id }}<br />

                                                    <br />

                                                    <b>Client : </b> <br />
                                                    {{ tabularData[key].vendor_booking.project.client.name }}<br />
                                                    Client ID :
                                                    {{ tabularData[key].vendor_booking.project.client.id }}<br />

                                                    <br />


                                                </small>


                                            </ng-container>
                                        </td>

                                    </ng-container>

                                    <td>
                                        <p>{{ tabularData[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </p>
                                        <p> <small> {{ tabularData[key].created_at | momentDateDiffPipe  }} (
                                                {{ tabularData[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small> </p>
                                    </td>

                                    <td>
                                        <p>{{ tabularData[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </p>
                                        <p> <small> {{ tabularData[key].updated_at | momentDateDiffPipe  }} (
                                                {{ tabularData[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small> </p>
                                    </td>





                                </tr>

                            </tbody>

                        </table>
                    </div>

                </div>

                <div class="mt-2">
                    <div class="d-flex justify-content-center ">

                        <div *ngIf="totalResults < 1" class="">
                            <p class="d-flex justify-content-center text-danger"> <b>No results found, try modifying
                                    your query </b></p>
                        </div>

                        <div *ngIf="totalResults > 0" class="">
                            <p class="d-flex justify-content-center"> <b>Total Results : {{ totalResults }} </b></p>


                            <a class="btn badge badge-pagination " *ngFor="let paginationLink of paginationLinks "
                                [ngClass]="  paginationLink == operationsForm.value.page ? 'badge-dark' : 'badge-light' "
                                [routerLink]="['./']" [queryParams]="{
                                    id : operationsForm.value.id,
                                    client_id_id : operationsForm.value.client_id_id,
                                    client_id_name : operationsForm.value.client_id_name,
            
                                    project_id_id : operationsForm.value.project_id_id,
                                    project_id_name : operationsForm.value.project_id_name,

                                    vendor_id_id : operationsForm.value.vendor_id_id,
                                    vendor_id_name : operationsForm.value.vendor_id_name,

                                    booking_id : operationsForm.value.booking_id,

            
                                    value : operationsForm.value.value,
                                    description : operationsForm.value.description,
                                    custom_field_id : operationsForm.value.custom_field_id,

                                    account_filter_type : operationsForm.value.account_filter_type,
                                    account_id : operationsForm.value.account_id,
        
                                    page : paginationLink == '...' ? '1' : paginationLink,
                                    pagination : operationsForm.value.pagination,
                                    sort_by : operationsForm.value.sort_by,
                                    sort_order : operationsForm.value.sort_order,
                                    report_type :  operationsForm.value.report_type
                           
                        }">
                                {{ paginationLink }}
                            </a>

                            <p class="d-flex justify-content-center mt-3"> <b> Page :
                                    {{ operationsForm.value.page }} </b></p>

                        </div>
                    </div>

                </div>



            </div>
        </div>
    </div>
</div>