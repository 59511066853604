<ng-container>


    <div class="card display-container-card">
        <div class="card-header card-header-bg display-container-card-bg">
            <p class="card-header-heading"> Add / Edit Applicable Taxes </p>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                    <!-- <table class="table table-striped table-responsive mt-5 table card-table table-vcenter text-nowrap"> -->
                    <thead>
                        <tr>
                            <th colspan="2" class="" scope="col">
                                <a (click)="isCreate = true; addEditFormVisible = true;" class="btn btn-primary btn-sm">
                                    <i class="fa fa-plus"></i> ADD TAX </a>
                            </th>

                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <!-- <th class="" scope="col"> </th> -->
                            <th class="reloadTaxUnitValues" (click)="refreshOnlyChildData()" scope="col">
                                <a class="link-dark"> <i class="fa fa-rotate-right"></i> Refresh </a>
                            </th>
                            <!-- <th class="" scope="col"> </th> -->

                        </tr>
                    
                        <tr>
                            <th class="bg-primary" scope="col"></th>
                            <th class="bg-primary" scope="col">ID</th>
                            <th class="bg-primary" scope="col">Tax Component</th>
                            <th class="bg-primary" scope="col">Tax Deducation</th>
                            <th class="bg-primary" scope="col">Tax </th>
                            <th class="bg-primary" scope="col">Timestamps</th>



                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="count < 1">
                            <td>No Records Found</td>
                        </tr>

                        <ng-container *ngIf="fetchedAllData && count > 0">



                            <tr *ngFor="let key of Object.keys(fetchedAllData)">
                                <td>
                                    <button (click)="fetchValueAndDisplayForm(fetchedAllData[key].id)"
                                        class="btn btn-secondary btn-sm">
                                        <i class="fa fa-pencil-square-o"></i> Edit </button>

                                </td>
                                <td>{{ fetchedAllData[key].id }}</td>
                                <td>{{ fetchedAllData[key].tax_unit.name  }}</td>

                                <td class="text-danger">
                                    {{ fetchedAllData[key].tax_unit.is_percentage == '1' ? fetchedAllData[key].tax_unit.value + ' % ' :  fetchedAllData[key].tax_unit.value | indianNumberFormat  }}
                                </td>


                                <td class="text-danger">{{  
                                    (fetchedAllData[key].tax_unit.is_percentage == '1' ? 
                                    (dataHolderService.percentageOfNumber( fetchedAllData[key].tax_unit.value ,  basePrice))   : 
                                    fetchedAllData[key].tax_unit.value ) | indianNumberFormat
                                
                                }}</td>


                                <td>

                                    <p>
                                        <small>
                                            Created At :
                                            <!-- <small>{{ fetchedAllData[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->

                                            <small> {{ fetchedAllData[key].created_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>

                                    <p>
                                        <small>

                                            Updated At :

                                            <!-- <small>{{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->
                                            <small> {{ fetchedAllData[key].updated_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>
                                </td>

                            </tr>

                         
    
                            <tr>

                                <th colspan="6" scope="col"> 
                                   <span class="badge badge-info">  Taxable Amount : {{ basePrice | indianNumberFormat }}   </span> 
                                   <span class="badge badge-info">  Tax : {{ totalTax | indianNumberFormat }}  </span>
                                   <span class="badge badge-info"> Order Price : {{ (basePrice + totalTax )  | indianNumberFormat }}  </span>

                                </th>
                             
    
                            </tr>

                         

                        </ng-container>

                        

               





                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <section class="fullScreenPopup" *ngIf="addEditFormVisible">
        <div class=" text-right mt-1 mb-1" (click)="addEditFormVisible = false; reloadData(); ">
            <button class="btn btn-primary"> <i class="fa fa-arrow-circle-o-left mr-2"></i> CANCEL</button>
        </div>


        <app-tax-unit-values-add-edit [isCreate]="isCreate" [type_of_data]="type_of_data"
            [table_column_id]="table_column_id" [fetchedData]="fetchedValue" (reloadData)="reloadData($event)">
        </app-tax-unit-values-add-edit>




    </section>



</ng-container>