<ng-container>


    <div class="card display-container-card">
        <div class="card-header card-header-bg display-container-card-bg">
            <p *ngIf="type_of_data == '2' || type_of_data == '3'" class="card-header-heading"> Add / Edit Transactions </p>
            <p *ngIf="type_of_data == '4'" class="card-header-heading">Manage Labour and Work Information</p>

        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                    <!-- <table class="table table-striped table-responsive mt-5 table card-table table-vcenter text-nowrap"> -->
                    <thead>
                        <tr>
                            <th class="" scope="col">
                                <a (click)="isCreate = true; addEditFormVisible = true;" class="btn btn-primary btn-sm">
                                    <i class="fa fa-plus"></i>  
                                    ADD  
                                    <ng-container *ngIf="type_of_data == '2' || type_of_data == '3'">TRANSACTION</ng-container> 
                                    <ng-container *ngIf="type_of_data == '4'">WORK INFORMATION</ng-container> 

                                 </a>
                            </th>

                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>

                            <ng-container *ngIf="type_of_data == '4'">
                                <th class="" scope="col"></th>
                                <th class="" scope="col"></th>
                             

                            </ng-container>
                            

                            


                            <!-- <th class="" scope="col"> </th> -->
                            <th class="reloadTransactionRecords" (click)="refreshOnlyChildData()" scope="col">
                                <a class="link-dark"> <i class="fa fa-rotate-right"></i> Refresh </a>
                            </th>
                            <!-- <th class="" scope="col"> </th> -->

                        </tr>

                        <tr>
                            <th class="bg-primary" scope="col"></th>
                            <th class="bg-primary" scope="col">
                                ID <br>
                                Payment / Transaction Date
                            </th>
                            <th class="bg-primary" scope="col">Amount </th>

                            <ng-container *ngIf="type_of_data == '4'"> 
                                <th class="bg-primary" scope="col">
                                    Labourer Type <br>
                                    Number of Workers
                                </th>
                                <th class="bg-primary" scope="col">
                                    Labour Type of Work <br>
                                    Payment Status
                                </th>
                            </ng-container>

                            <th class="bg-primary" scope="col">Title</th>
                            <th class="bg-primary" scope="col">Period</th>
                            <th class="bg-primary" scope="col">Timestamps</th>



                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="count < 1">
                            <td>No Records Found</td>
                        </tr>

                        <ng-container *ngIf="fetchedAllData && count > 0">



                            <tr *ngFor="let key of Object.keys(fetchedAllData)">
                                <td>
                                    <button (click)="fetchValueAndDisplayForm(fetchedAllData[key].id)"
                                        class="btn btn-secondary btn-sm">
                                        <i class="fa fa-pencil-square-o"></i> Edit </button>

                                </td>
                                <td>
                                    {{ fetchedAllData[key].id }} <br>
                                    {{ fetchedAllData[key].transaction_date ? ( fetchedAllData[key].transaction_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}
                                </td>
                               

                                <td
                                    [ngClass]="{ 'text-danger' : fetchedAllData[key].is_credit != '1' , 'text-success' : fetchedAllData[key].is_credit == '1'  }">
                                    <p> {{ fetchedAllData[key].amount | indianNumberFormat }} </p>
                                    <!-- <p> <small> (
                                        {{ fetchedAllData[key].amount | numberToIndianWords  }} ) </small></p> -->
                                </td>

                                
                                <ng-container *ngIf="type_of_data == '4'"> 
                                    <td>
                                        {{ fetchedAllData[key].labourer_type.name }} <br>
                                        {{ fetchedAllData[key].number_of_workers }}
                                    </td>
                                    <td>
                                        {{ fetchedAllData[key].labour_type_of_work.name }} <br>
                                        <ng-container *ngIf="fetchedAllData[key].labour_payment_done == '1'">
                                            <span style="color:green;">PAYMENT DONE</span>
                                        </ng-container>
                                        <ng-container *ngIf="fetchedAllData[key].labour_payment_done == '0'">
                                            <span style="color:red;">PAYMENT PENDING</span>
                                        </ng-container>
                                    </td>
                                  
                                </ng-container>

                                <td>{{ fetchedAllData[key].title | nullWithDefault   }}</td>

                                <td>{{ fetchedAllData[key].from_date ? ( fetchedAllData[key].from_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}
                                    -
                                    {{ fetchedAllData[key].to_date ? ( fetchedAllData[key].to_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}
                                </td>








                                <td>

                                    <p>
                                        <small>
                                            Created At :
                                            <!-- <small>{{ fetchedAllData[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->

                                            <small> {{ fetchedAllData[key].created_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>

                                    <p>
                                        <small>

                                            Updated At :

                                            <!-- <small>{{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->
                                            <small> {{ fetchedAllData[key].updated_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>
                                </td>

                            </tr>

                            <tr>

                                <th colspan="8" scope="col">

                                    <div class="btn-group btn-group-square" role="group" aria-label="">
                                        <button class="btn btn-dark" type="button">
                                            Total Price <br>  
                                            {{ total_price   | indianNumberFormat }}
                                         </button>
                                        <button class="btn btn-dark" type="button">
                                            Pending Amount  <br> 
                                            {{ total_price - amount_received_till_now | indianNumberFormat }}
                                        </button>
                                       
                                        <button class="btn btn-dark" type="button">
                                            Transactions Done  <br> 
                                            {{ amount_received_till_now   | indianNumberFormat }}
                                        </button>
                                    </div>

                                

                                </th>


                            </tr>



                        </ng-container>









                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <section class="fullScreenPopup" *ngIf="addEditFormVisible">
        <div class=" text-right mt-1 mb-1" (click)="addEditFormVisible = false; reloadData(); ">
            <button class="btn btn-primary"> <i class="fa fa-arrow-circle-o-left mr-2"></i> CANCEL</button>
        </div>


        <app-transaction-records-add-edit [isCreate]="isCreate" [type_of_data]="type_of_data"
            [table_column_id]="table_column_id" [is_credit]="is_credit" [fetchedData]="fetchedValue"
            (reloadData)="reloadData($event)">
        </app-transaction-records-add-edit>




    </section>



</ng-container>