import { DataHolderService } from './../services/data-holder.service';
import { SearchAndAutoCompleteService } from './../services/search-and-auto-complete.service';
import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, FormControl, Validator, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-vendors',
  templateUrl: './autocomplete-vendors.component.html',
  styleUrls: ['./autocomplete-vendors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteVendorsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutocompleteVendorsComponent),
      multi: true
    }
  ]
})
export class AutocompleteVendorsComponent implements OnInit {


  fetchedItems$: Observable<any>;
  isLoading = false;
  searchInput$ = new Subject<string>();

  // Pre selected client id
  idForModel: any;

  @Input() preSelectedValue: any;


  @Input() hasPreSelectedValue: any = false;


  vendorForm: FormGroup = new FormGroup({
    vendor_id: new FormControl('-1')
  });


  constructor(
    private searchAndAutoCompleteService: SearchAndAutoCompleteService,
    public dataHolderService: DataHolderService,
  ) { }

  ngOnInit(): void {
    this.idForModel = this.hasPreSelectedValue && this.preSelectedValue && 'id' in this.preSelectedValue ? this.preSelectedValue.id : '-1';

    this.searchAndAutoFill();

  }
  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    val && this.vendorForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.vendorForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.vendorForm.disable() : this.vendorForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.vendorForm.valid ? null : { invalidForm: { valid: false, message: "Invalid" } };
  }

  trackByFn(item) {
    return item.id;
  }

  private searchAndAutoFill() {

    var defaultItems = [
      {
        id: '-1',
        name: 'Type here'
      }
    ];

    if (this.hasPreSelectedValue && this.preSelectedValue) {
      defaultItems = [
        this.preSelectedValue,
        {
          id: '-1',
          name: 'Type here'
        }
      ];
    }


    this.fetchedItems$ = concat(
      of(defaultItems), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term => this.searchAndAutoCompleteService.getUsers(term, "is_vendor").pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.isLoading = false)
        ))
      )
    );
  }

}
