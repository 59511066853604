<ng-container [formGroup]="projectForm">
    <div class="col-form-label"> <label for="project_id"> Sale / Project ID : </label> </div>
    <ng-select name="project_id" class="form-control form-control-info ng-select-outline" #select
        [items]="fetchedItems$ | async" bindLabel="name" bindValue="id" [virtualScroll]="true" [trackByFn]="trackByFn"
        [loading]="isLoading" typeToSearchText="Please enter 1 or more characters" [typeahead]="searchInput$"
        formControlName="project_id" [(ngModel)]="idForModel">

        <ng-template ng-option-tmp let-item="item" let-index="index">
            <!-- -->
            <p>
                <ng-container *ngIf="item.id!=-1">
                    ID : {{ item.id }} ,
                </ng-container>


                <b>{{item.name}}</b>

                <span>

                    <span class="m-2" *ngIf="item.id!=-1">

                        <small *ngIf="item.order_date"> {{ item.order_date | momentDateTransformPipe : ('DD/MM/YYYY') }}
                        </small>
                        -

                        <small *ngIf="item.completion_date">
                            {{ item.completion_date | momentDateTransformPipe : ('DD/MM/YYYY') }} </small>

                    </span>

                    <span class="badge badge-success" *ngIf="item.status == '2'">Completed</span>
                    <span class="badge badge-dark" *ngIf="item.status == '0'">On
                        Going</span>
                    <span class="badge badge-primary" *ngIf="item.status== '1'">Upcoming</span>
                    <span class="badge badge-warning" *ngIf="item.status == '4'">Planning / Estimation</span>
                    <span class="badge badge-info" *ngIf="item.status  == '5'">Quotation</span>
                </span>

                <br>

                <span class="m-2" *ngIf="item.client && item.client.id">


                    <img class="m-2 rounded-circle" height="25" width="25"
                        src="{{ (item.client.profile_picture_url &&  item.client.profile_picture_url.length > 5) ? (dataHolderService.apiBaseUrl + '/auth/get-profile-photo/' + item.client.id ) : '/assets/images/dummy.png'}}" />
                    
                        {{ item.client.name }} 

                    </span>


            </p>
        </ng-template>

    </ng-select>

    <p *ngIf="projectForm.value.project_id && projectForm.value.project_id!='-1'">
        <small>Project ID : <a target="_blanc" [routerLink]="[ '/projects/edit/' , projectForm.value.project_id ]"
                class="li">
                {{ projectForm.value.project_id }} </a>
        </small>
    </p>


</ng-container>