import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToIndianWords'
})
export class NumberToIndianWordsPipe implements PipeTransform {

  private static readonly zeroTo99: string[] = [];
  private static readonly place: string[] = 'Thousand|Lakh|Crore|Arab|Kharab|Nil'.split('|');

  static {
    const ones: string[] =
      '|One|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen'.split(
        '|'
      );

    const tens: string[] = '||Twenty|Thirty|Forty|Fifty|Sixty|Seventy|Eighty|Ninety'.split('|');

    for (let i = 0; i < 100; i++) {
      const t: number = Math.floor(i / 10);
      const o: number = i % 10;
      NumberToIndianWordsPipe.zeroTo99.push(t < 2 ? ones[i] : tens[t] + (o ? ' ' + ones[o] : ''));
    }
  }

  public static convert(x: string): string {
    let n: number = x.length;
    x = n === 0 ? '00' : n === 1 || n % 2 === 0 ? '0' + x : x;
    n = x.length;
    let r = NumberToIndianWordsPipe.zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
    if (n >= 1) {
      const v: string = NumberToIndianWordsPipe.zeroTo99[x.charCodeAt((n -= 1)) - 48];
      if (v) r = v + ' Hundred' + (r ? ' ' + r : '');
    }
    for (let i = 0; n > 0; i++) {
      const v: string = NumberToIndianWordsPipe.zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
      if (v) r = v + ' ' + NumberToIndianWordsPipe.place[i] + (r ? ' ' + r : '');
    }
    return r;
  }

  transform(value: any): any {
    const [rupee, paisa] = value.toString().split('.');
    return paisa ? `${NumberToIndianWordsPipe.convert(rupee)} Rupees and ${NumberToIndianWordsPipe.convert(paisa)} Paisa ` : `${NumberToIndianWordsPipe.convert(rupee)} Rupees `;
  }

}
