import { AlertsService } from './alerts.service';
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Router  } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataHolderService {

  apiBaseUrl: string = "/api";

  serverBaseUrl: string = "/";

  projectTitle: string = "";

  userInfo: any = null;

  designations: any;

  customFields: any;

  // scopeOfWorks: any;

  taxUnits: any;

  labels: any;

  application_configuration: any;

  units: any;

  available_booking_items: any;

  labourer_types: any;

  labour_type_of_works: any;

  items_by_labels : any;



  paginationOptions = [
    1, 5, 10, 15, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 10000000000
  ];

  paginationDefault = 10;

  public kolkovEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'yes',

  }




  constructor(private metaTagService: Meta, private titleService: Title, private alertsService: AlertsService, private router: Router , private httpClient: HttpClient) { }

  public updatePageMeta(pageTitle: any = null): void {

    let staticPart = this.userInfo ? ' | ' + this.userInfo.name + ' - BERP by Blindersoe' : ' - BERP by Blindersoe';

    this.titleService.setTitle(pageTitle != null ? pageTitle + staticPart : '');


  }

  showField(strs): boolean {
    var accessArrays = strs.split(",");
    var hasAccess = false;
    for (let i = 0; i < accessArrays.length; i++) {
      if (this.userInfo[accessArrays[i]] == '1') {
        hasAccess = true;
        break;
      }
    }
    return hasAccess;
  }

  percentageOfNumber(percentage, number, fixDigits = 2) {
    return ((percentage / 100) * number).toFixed(fixDigits);
  }

  scrollToElementById(id) {
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  openTabSet(e: any) {
    var tab = e.target.closest('li');

    var tabId = tab.getAttribute('data-open-tab');

    // console.log(tab.getAttribute('data-open-tab'));

    // Remove Active Class From Tabs

    var tabs = tab.closest('.app-tabsets-nav').querySelectorAll('li');

    tabs.forEach(element => {
      element.querySelector(".nav-link").classList.remove('btn-primary');
    });

    // Add Active Class to Tab

    tab.querySelector(".nav-link").classList.add('btn-primary');


    var tabsetsContainer = tab.closest('.app-tabsets-nav').closest(".app-tabsets-container");

    var tabsetsContentContainer = tabsetsContainer.querySelector(".app-tabsets-content-parent");

    var tabsetsContent = tabsetsContentContainer.querySelectorAll(".tab-pane-contents");

    tabsetsContent.forEach(element => {
      element.classList.add('tab-pane');

      if (element.getAttribute("id") == tabId) {
        element.classList.remove('tab-pane');
      }

    });


  }

  logout() {
    this.alertsService.addAlert('info', 'Logging out..');

    return this.httpClient.post( this.apiBaseUrl + '/auth/logout' , {}).subscribe((response: any) => {
      this.alertsService.removeAlert();
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);

      // this.myProfileService.fetchedAccountInfo = response["user"];


      setTimeout(() => {
        this.router.navigate(['/auth/login']);
      }, 500);
      // this.updateForm(response);
      // this.categoryName = "";
    },
      error => {
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
      });
  }

}
