import { DataHolderService } from 'src/app/shared/services/data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FilterCustomFieldValuesService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  filterCustomFieldValues(body , type_of_data): any {

    if(type_of_data == '2'){
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/projects/filter-custom-field-values', body );

    }

    if(type_of_data == '1'){
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/filter-custom-field-values', body );

    }

    if(type_of_data == '3'){
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/vendor-bookings/filter-custom-field-values', body );

    }
  }
}
