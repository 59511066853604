import { Component, OnInit  } from '@angular/core';

@Component({
  selector: 'app-export-records-buttons',
  templateUrl: './export-records-buttons.component.html',
  styleUrls: ['./export-records-buttons.component.scss']
})
export class ExportRecordsButtonsComponent implements OnInit {


  reportTypes = ['csv','pdf'];

  // queryParamsArray : any;

  Object = Object;

  constructor() { }

  ngOnInit(): void {

    // this.reportTypes.forEach((reportType)=>{
    //   this.queryParams['report_type'] = reportType;
    //   this.queryParamsArray.push(this.queryParams);

    // });

    // console.log(this.queryParamsArray , this.queryParams);

  }

}
