import { AlertsService } from './../services/alerts.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFieldValuesService } from '../services/custom-field-values.service';
import { DataHolderService } from '../services/data-holder.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-custom-field-values-add-edit',
  templateUrl: './custom-field-values-add-edit.component.html',
  styleUrls: ['./custom-field-values-add-edit.component.scss']
})
export class CustomFieldValuesAddEditComponent implements OnInit {

  @Input() isCreate: boolean | string = false;

  @Input() type_of_data: number | string;

  @Input() table_column_id: number | string = '';

  @Output() reloadData = new EventEmitter<any>();

  Object = Object;


  isCreateSub: any;

  public validate = false;

  public loading = false;

  operationsForm: FormGroup;

  @Input() fetchedCustomFieldValue : any = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public customFieldValuesService: CustomFieldValuesService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.operationsForm = this.formBuilder.group({
      id: ["-1", Validators.required],
      type_of_data: [this.type_of_data, Validators.required],
      value: ["NA", [Validators.required]],
      description: ["", []],
      attachment_url: ["", []],
      sort_order: ["0", []],
      custom_field_id: ["-1", Validators.required],
      table_column_id: [this.table_column_id, Validators.required],
    });

    if (!this.isCreate) {
      this.updateForm();
    }

    // console.log(this.dataHolderService);


  }

  onOperation(): any {

    this.alertsService.removeAlert();
    this.validate = true;
    this.loading = false;

    // console.log(this.operationsForm.invalid);

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    this.loading = true;
    const formData = new FormData(<HTMLFormElement>document.getElementById('customFieldFormWithData'));


    if (this.isCreate) {
      this.alertsService.addAlert('info', 'Adding Data...');
      this.addOperation(formData);
      return;
    }
    else {
      this.alertsService.addAlert('info', 'Updating Data..');
      this.updateOperation(formData);
      return;
    }

  }

  addOperation(formData): void {

    this.customFieldValuesService.addCustomFieldValue(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);


    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

  updateOperation(formData): void {


    this.customFieldValuesService.updateCustomFieldValue(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });

  }

  updateForm(response = null) {
    if (response != null) {
      this.alertsService.addAlert("success", response.message);
    }

    if(response){
      this.fetchedCustomFieldValue = response['data'];

    }


    this.operationsForm.controls.id.setValue(this.fetchedCustomFieldValue.id);
    this.operationsForm.controls.value.setValue(this.fetchedCustomFieldValue.value);
    this.operationsForm.controls.type_of_data.setValue(this.fetchedCustomFieldValue.type_of_data);
    this.operationsForm.controls.description.setValue(this.fetchedCustomFieldValue.description);
    this.operationsForm.controls.sort_order.setValue(this.fetchedCustomFieldValue.sort_order);
    this.operationsForm.controls.custom_field_id.setValue(this.fetchedCustomFieldValue.custom_field_id);
    this.operationsForm.controls.table_column_id.setValue(this.fetchedCustomFieldValue.table_column_id);



  }

  deleteAttachment() {
    this.alertsService.addAlert('info', 'Removing Attachment..');

    this.customFieldValuesService.deleteAttachment(this.fetchedCustomFieldValue.id).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

      // this.myProfileService.fetchedAccountInfo = response["user"];



    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

  deleteOperation() {
    this.alertsService.addAlert('info', 'Removing Custom Field Value..');

    this.customFieldValuesService.deleteCustomFieldValue(this.fetchedCustomFieldValue.id).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

      // this.myProfileService.fetchedAccountInfo = response["user"];



    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

}
