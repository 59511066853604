import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient , private dataHolderService : DataHolderService) { }

  login(body) : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/auth/login' , body);
  }
  logout() : any{
    return this.httpClient.post( this.dataHolderService.apiBaseUrl + '/auth/logout' , {});
  }

  checkAuthentication(): any {
    return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/auth/access-check', {});
  }

  refreshFieldsData(){
    return this.httpClient.get(this.dataHolderService.apiBaseUrl + `/auth/fetch-meta`);

  }
}
