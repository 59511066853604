<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 *ngIf="type_of_data == '2' || type_of_data == '3'">{{ isCreate ? 'ADD TRANSACTION RECORD' : 'UPDATE TRANSACTION RECORD' }}</h5>
                    <h5 *ngIf="type_of_data == '4'">{{ isCreate ? 'ADD WORK, LABOUR INFORMATION' : 'UPDATE WORK, LABOUR INFORMATION' }}</h5>

                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm" >

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>

                              <input name="is_credit" class="form-control" type="hidden"
                                formControlName="is_credit" readonly>    

                        </div>

                        <div *ngIf="!isCreate" class="form-row">

                            <div class="col-md-4 mb-3">
                                <label for="id">ID : </label>

                                <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                    formControlName="id" readonly>

                            </div>

                        </div>

                        <div class="form-row" *ngIf="type_of_data == '4'">

                            <div class="col-md-3 mb-3">
                                <label for="labourer_type_id">Select Labourer Type : <span> * </span> </label>
                             
                                <select class="form-control" placeholder="Select Labourer Type .." formControlName="labourer_type_id"
                                    name="labourer_type_id" id="labourer_type_id">

                                    <option value="-1">Select Labourer Type</option>
                                    <option *ngFor="let key of Object.keys(dataHolderService.labourer_types)"
                                        [value]="dataHolderService.labourer_types[key].id">
                                        {{ dataHolderService.labourer_types[key].name }}
                                    </option>
                                 
                                </select>
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.labourer_type_id == '-1'">
                                        *Please select Labourer Type
                                    </span>
                                </div>


                                <div class="mt-1 mb-1">
                                    <a routerLink="/settings/labourer-types/create"> Not in the List? Add Here </a>
                                    <br>
                                    <a routerLink="/settings/labourer-types"> Manage Labourer Types </a>
                                    <br>

                                </div>
                            </div>

                            <div class="col-md-3 mb-3">
                                <label for="labour_type_of_work_id">Select Labour Type of Work : <span> * </span> </label>
                             
                                <select class="form-control" placeholder="Select Labour Type of Work .." formControlName="labour_type_of_work_id"
                                    name="labour_type_of_work_id" id="labour_type_of_work_id">

                                    <option value="-1">Select Labour Type of Work : </option>
                                    <option *ngFor="let key of Object.keys(dataHolderService.labour_type_of_works)"
                                        [value]="dataHolderService.labour_type_of_works[key].id">
                                        {{ dataHolderService.labour_type_of_works[key].name }}
                                    </option>
                                 
                                </select>
                                <app-refresh-fields-data></app-refresh-fields-data>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.value.labour_type_of_work_id == '-1'">
                                        *Please Select Labour Type of Work
                                    </span>
                                </div>


                                <div class="mt-1 mb-1">
                                    <a routerLink="/settings/labour-type-of-works/create"> Not in the List? Add Here </a>
                                    <br>
                                    <a routerLink="/settings/labour-type-of-works"> Manage Labour Type of Works </a>
                                    <br>

                                </div>
                            </div>

                            <div class="col-md-3 mb-3">

                                <label for="number_of_workers">Number of workers </label>

                                <input class="form-control" id="number_of_workers" type="number" placeholder="Number of workers"
                                    formControlName="number_of_workers" name="number_of_workers">

                              

                            </div>
                            <div class="col-md-3 mb-3">
                                <label for="labour_payment_done">Payment Done: <span> * </span> </label>
                             
                                <select class="form-control" placeholder="Select Labour Type of Work .." formControlName="labour_payment_done"
                                    name="labour_payment_done" id="labour_payment_done">

                                    <option value="1">Yes</option>
                                    <option value="0">No </option>
                                </select>
                               
                              
                            </div>

                        </div>


                        <div class="form-row">

                            
                            <div class="col-md-4 mb-3">
                                <label for="title"> Title (Notes) : </label>
                                <input class="form-control" id="title" formControlName="title" name="title" />
                                <p  *ngIf="type_of_data == '2' || type_of_data == '3'"> A small comment about the Transaction. For eg: Advance Payment. These comments will be visible in Invoices, Vouchers etc</p>
                               
                                <div class="form-error-message">
                                  
                                </div>

                            </div>

                            <div class="col-md-4 mb-3">

                                <label for="amount">  Amount </label>

                                <input class="form-control" id="amount" step="any" type="number" placeholder="Amount"
                                    formControlName="amount" name="amount">

                                <ng-container *ngIf="operationsForm.value.amount > 0">
                                    <p><small>{{ operationsForm.value.amount | indianNumberFormat  }}</small> <small> (
                                            {{ operationsForm.value.amount | numberToIndianWords  }} ) </small> </p>

                                </ng-container>

                                <div class="form-error-message">
                                    <span *ngIf="validate && operationsForm.controls['amount'].errors?.required">
                                        *required</span>
                                </div>

                            </div>

                            <div class="col-md-4 mb-3">

                                <label for="transaction_date">Transaction Date : </label>
                                <input class="form-control" id="transaction_date" type="date" placeholder="Transaction Date"
                                    formControlName="transaction_date" name="transaction_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['transaction_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>


                        </div>

                        <div class="form-row">

                            <div class="col-md-4 mb-3">

                                <label for="from_date">From Date : </label>
                                <input class="form-control" id="from_date" type="date" placeholder="From Date"
                                    formControlName="from_date" name="from_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['from_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                            <div class="col-md-4 mb-3">

                                <label for="to_date">To Date : </label>
                                <input class="form-control" id="to_date" type="date" placeholder="To Date"
                                    formControlName="to_date" name="to_date">
                                <div class="form-error-message">
                                    <span
                                        *ngIf="validate && operationsForm.controls['to_date'].errors?.invalidMsg">
                                        *Select a
                                        valid date</span>
                                </div>

                            </div>

                            <div class="col-md-4 mb-3">
                                <label for="sort_order">Sort Order : </label>
                                <input class="form-control" id="sort_order" formControlName="sort_order"
                                    name="sort_order" />
                            </div>

                        </div>

                       

                        <div class="form-row" *ngIf="type_of_data == '17'">

                            <div class="col-md-6 mb-3">
                                <label for="payslip_factor_id">Select Payslip factor id : </label>
                             
                                <select class="form-control" placeholder="Select Payslip factor id.." formControlName="payslip_factor_id"
                                    name="payslip_factor_id" id="payslip_factor_id">

                                    <option>-1</option>
                                 
                                </select>

                                <div class="mt-1 mb-1">
                                  

                                </div>
                            </div>

                        </div>


                        <div class="form-row">
                            <div class="col-md-12 mt-3 mb-3">
                                <label for="description"> Additional Notes : </label>
                                <p> <small> You can add receipts , images, notes etc </small> </p>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 preview-details">
                                <angular-editor id="description" [config]="dataHolderService.kolkovEditorConfig"
                                    formControlName="description"></angular-editor>

                            </div>
                        </div>

        
                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD RECORD' : 'UPDATE RECORD' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary m-2" [class.loader--text]="loading"
                            [disabled]="loading"
                            type="button"><span>{{ loading ? '' : ' REMOVE RECORD' }}</span></button>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>