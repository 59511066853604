import { AccountsService } from './../../accounts/services/accounts.service';
import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SearchAndAutoCompleteService {

  constructor(
    private http: HttpClient,
    private accountsService: AccountsService,
    private dataHolderService: DataHolderService
  ) {

  }


  getUsers(term: string | null = null , userType = 'is_client'): Observable<any> {

    var url = this.dataHolderService.apiBaseUrl + '/auth/client-search-auto-fill?term=' + term;

    if(userType == 'is_client'){
      var url = this.dataHolderService.apiBaseUrl + '/auth/client-search-auto-fill?term=' + term;
    }
    if(userType == 'is_agent'){
      var url = this.dataHolderService.apiBaseUrl + '/auth/agents-search-auto-fill?term=' + term;
    }
    if(userType == 'is_vendor'){
      var url = this.dataHolderService.apiBaseUrl + '/auth/vendors-search-auto-fill?term=' + term;
    }


    return this.http
      .get<any>(url)
      .pipe(map(resp => {
        if (resp.status) {
          return resp.data.data;

        }

      })
      );
  }

  getProjects(term: string | null = null ): Observable<any> {

    var url = this.dataHolderService.apiBaseUrl + '/projects/projects-search-auto-fill?term=' + term;

    return this.http
      .get<any>(url)
      .pipe(map(resp => {
        if (resp.status) {
          return resp.data.data;

        }

      })
      );
  }

}
