import { DataHolderService } from './data-holder.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FilterApplicationFilesService {

  constructor(private httpClient: HttpClient, private dataHolderService: DataHolderService) { }

  filterApplicationFiles(body, type_of_data): any {

    if (type_of_data == '14') {
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/projects/filter-invoices', body);

    }

    if (type_of_data == '18') {
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/projects/filter-estimates', body);

    }

    if (type_of_data == '15') {
      return this.httpClient.post(this.dataHolderService.apiBaseUrl + '/vendor-bookings/filter-vouchers', body);

    }
  }
}
