import { MomentDatesPipe } from './moment-dates.pipe';
import { NumberToIndianWordsPipe } from './number-to-indian-words.pipe';
import { IndianNumberFormatPipe } from './indian-number-format.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { NullWithDefaultPipe } from './null-with-default.pipe';
import { MomentDateTransformPipePipe } from './moment-date-transform-pipe.pipe';
import { MomentDateDiffPipePipe } from './moment-date-diff-pipe.pipe';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { MegaMenuComponent } from './components/header/elements/mega-menu/mega-menu.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { BookmarkComponent } from './components/header/elements/bookmark/bookmark.component';
import { CartComponent } from './components/header/elements/cart/cart.component';
import { MessageBoxComponent } from './components/header/elements/message-box/message-box.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
// Services
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { AlertsComponent } from './alerts/alerts.component';
import { DisplayCustomFieldValuesComponent } from './display-custom-field-values/display-custom-field-values.component';
import { CustomFieldValuesAddEditComponent } from './custom-field-values-add-edit/custom-field-values-add-edit.component';
import { AutocompleteClientsComponent } from './autocomplete-clients/autocomplete-clients.component';
import { AutocompleteAgentsComponent } from './autocomplete-agents/autocomplete-agents.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DisplayTaxUnitValuesComponent } from './display-tax-unit-values/display-tax-unit-values.component';
import { TaxUnitValuesAddEditComponent } from './tax-unit-values-add-edit/tax-unit-values-add-edit.component';
import { DisplayTransactionRecordsComponent } from './display-transaction-records/display-transaction-records.component';
import { TransactionRecordsAddEditComponent } from './transaction-records-add-edit/transaction-records-add-edit.component';
import { DisplayRemaindersComponent } from './display-remainders/display-remainders.component';
import { RemaindersAddEditComponent } from './remainders-add-edit/remainders-add-edit.component';
import { AutocompleteLabelsComponent } from './autocomplete-labels/autocomplete-labels.component';
import { RefreshFieldsDataComponent } from './refresh-fields-data/refresh-fields-data.component';
import { ExportRecordsButtonsComponent } from './export-records-buttons/export-records-buttons.component';
import { FilterTransactionRecordsComponent } from './filter-transaction-records/filter-transaction-records.component';
import { AutocompleteProjectsComponent } from './autocomplete-projects/autocomplete-projects.component';
import { FilterRemaindersComponent } from './filter-remainders/filter-remainders.component';
import { FilterCustomFieldValuesComponent } from './filter-custom-field-values/filter-custom-field-values.component';
import { FilterApplicationFilesComponent } from './filter-application-files/filter-application-files.component';
import { AutocompleteVendorsComponent } from './autocomplete-vendors/autocomplete-vendors.component';
import { DisplayBookedItemsComponent } from './display-booked-items/display-booked-items.component';
import { BookedItemsAddEditComponent } from './booked-items-add-edit/booked-items-add-edit.component';
// import { DisplayApplicationConfigurationComponent } from './display-application-configuration/display-application-configuration.component';
// import { ApplicationConfigurationAddEditComponent } from './application-configuration-add-edit/application-configuration-add-edit.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { DisplayMaterialUsedComponent } from './display-material-used/display-material-used.component';
import { MaterialUsedAddEditComponent } from './material-used-add-edit/material-used-add-edit.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    AlertsComponent,
    MomentDateDiffPipePipe,
    MomentDateTransformPipePipe,
    MomentDatesPipe,
    IndianNumberFormatPipe,
    NumberToIndianWordsPipe,
    NullWithDefaultPipe,
    DisplayCustomFieldValuesComponent,
    CustomFieldValuesAddEditComponent,
    AutocompleteClientsComponent,
    AutocompleteAgentsComponent,
    DisplayTaxUnitValuesComponent,
    TaxUnitValuesAddEditComponent,
    DisplayTransactionRecordsComponent,
    TransactionRecordsAddEditComponent,
    DisplayRemaindersComponent,
    RemaindersAddEditComponent,
    AutocompleteLabelsComponent,
    RefreshFieldsDataComponent,
    ExportRecordsButtonsComponent,
    FilterTransactionRecordsComponent,
    AutocompleteProjectsComponent,
    FilterRemaindersComponent,
    FilterCustomFieldValuesComponent,
    FilterApplicationFilesComponent,
    AutocompleteVendorsComponent,
    DisplayBookedItemsComponent,
    BookedItemsAddEditComponent,
    DisplayMaterialUsedComponent,
    MaterialUsedAddEditComponent,
    // DisplayApplicationConfigurationComponent,
    // ApplicationConfigurationAddEditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule,
    NgSelectModule,
    AngularEditorModule,
    PerfectScrollbarModule,

  ],
  providers: [
    NavService,
    LayoutService    
    
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    MomentDateDiffPipePipe,
    MomentDateTransformPipePipe,
    MomentDatesPipe,
    IndianNumberFormatPipe,
    NumberToIndianWordsPipe,
    NullWithDefaultPipe,
    DisplayCustomFieldValuesComponent,
    DisplayTaxUnitValuesComponent,
    TaxUnitValuesAddEditComponent,
    DisplayTransactionRecordsComponent,
    TransactionRecordsAddEditComponent,
    DisplayRemaindersComponent,
    RemaindersAddEditComponent,
    DisplayBookedItemsComponent,
    BookedItemsAddEditComponent,
    DisplayMaterialUsedComponent,
    MaterialUsedAddEditComponent,
    AutocompleteClientsComponent,
    AutocompleteAgentsComponent,
    AutocompleteVendorsComponent,
    AutocompleteLabelsComponent,
    AutocompleteProjectsComponent,
    AngularEditorModule,
    RefreshFieldsDataComponent,
    FilterTransactionRecordsComponent,
    FilterRemaindersComponent,
    FilterCustomFieldValuesComponent,
    FilterApplicationFilesComponent,
    PerfectScrollbarModule
  ],
})
export class SharedModule { }
