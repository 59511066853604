<ng-container>


    <div class="card display-container-card">
        <div class="card-header card-header-bg display-container-card-bg">
            <p class="card-header-heading"> Manage Items / Materials Used </p>

            <div class="mt-2 mb-2 font-italic">

                <p> <i class="fa fa-info-circle"></i> Operations perfomed here do not effect Inventory, The purpose of this section is keeping track of Item / Material Usage </p>
            

            </div>

        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                    <!-- <table class="table table-striped table-responsive mt-5 table card-table table-vcenter text-nowrap"> -->
                    <thead>
                        <tr>
                            <th class="" scope="col">
                                <a (click)="isCreate = true; addEditFormVisible = true;" class="btn btn-primary btn-sm">
                                    <i class="fa fa-plus"></i> Add Material Use </a>
                            </th>

                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>



                            <!-- <th class="" scope="col"> </th> -->
                            <th class="reloadBookedItems" (click)="refreshOnlyChildData()" scope="col">
                                <a class="link-dark"> <i class="fa fa-rotate-right"></i> Refresh </a>
                            </th>
                            <!-- <th class="" scope="col"> </th> -->

                        </tr>

                        <tr>
                            <th class="bg-primary" scope="col"></th>
                            <th class="bg-primary" scope="col">
                                Record ID <br>
                                <small> Date </small> <br>
                                <small> Title / Notes </small>

                            </th>
                            <th class="bg-primary" scope="col"> Item / Material </th>
                            <th class="bg-primary" scope="col"> Unit of measurement </th>
                            <th class="bg-primary" scope="col"> Quantity </th>
                            <th class="bg-primary" scope="col">Timestamps</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="count < 1">
                            <td>No Records Found</td>
                        </tr>

                        <ng-container *ngIf="fetchedAllData && count > 0">



                            <tr *ngFor="let key of Object.keys(fetchedAllData)">
                                <td>
                                    <button (click)="fetchValueAndDisplayForm(fetchedAllData[key].id)"
                                        class="btn btn-secondary btn-sm">
                                        <i class="fa fa-pencil-square-o"></i> Edit </button>

                                </td>
                                <td>
                                    {{ fetchedAllData[key].id }} <br>

                                    <small>

                                        {{ fetchedAllData[key].transaction_date ? ( fetchedAllData[key].transaction_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}


                                     <br>

                                       {{ fetchedAllData[key].title  | nullWithDefault }} <br>

                                     </small>



                                </td>

                                <td> {{ fetchedAllData[key].available_booking_item.name    }} </td>
                                <td> {{  fetchedAllData[key].unit.fullform + ' ( ' + fetchedAllData[key].unit.symbol  + ' )' }}
                                </td>

                                <td> {{ fetchedAllData[key].quantity }} </td>





                                <td>

                                    <p>
                                        <small>
                                            Created At :
                                            <!-- <small>{{ fetchedAllData[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->

                                            <small> {{ fetchedAllData[key].created_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>

                                    <p>
                                        <small>

                                            Updated At :

                                            <!-- <small>{{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->
                                            <small> {{ fetchedAllData[key].updated_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>
                                </td>

                            </tr>





                        </ng-container>









                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <section class="fullScreenPopup" *ngIf="addEditFormVisible">
        <div class=" text-right mt-1 mb-1" (click)="addEditFormVisible = false; reloadData(); ">
            <button class="btn btn-primary"> <i class="fa fa-arrow-circle-o-left mr-2"></i> CANCEL</button>
        </div>


       <app-material-used-add-edit [isCreate]="isCreate" [type_of_data]="type_of_data"
            [table_column_id]="table_column_id" [fetchedData]="fetchedValue" (reloadData)="reloadData($event)">
        </app-material-used-add-edit> 




    </section>



</ng-container>