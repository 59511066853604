<div *ngIf="alertsService.active" class="topfixed">
    <ngb-alert [type]="alertsService.type" [dismissible]="alertsService.type!='info'" (close)="alertsService.active = false">
       <div class="alert-msg-cont">  
      
        <i class="fa" [ngClass]="{ 
            'fa-spin fa-spinner' :  alertsService.type == 'info' ,
            'fa-check-square-o' :  alertsService.type == 'success',
            'fa-ban' :  alertsService.type == 'danger'
        }" > </i>

        {{ alertsService.message  }}
    
      </div> 
    </ngb-alert>
</div>