import { TaxUnitValuesService } from './../services/tax-unit-values.service';
import { AlertsService } from './../services/alerts.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataHolderService } from '../services/data-holder.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tax-unit-values-add-edit',
  templateUrl: './tax-unit-values-add-edit.component.html',
  styleUrls: ['./tax-unit-values-add-edit.component.scss']
})
export class TaxUnitValuesAddEditComponent implements OnInit {

  @Input() isCreate: boolean | string = false;

  @Input() type_of_data: number | string;

  @Input() table_column_id: number | string = '';

  @Output() reloadData = new EventEmitter<any>();

  Object = Object;

  public validate = false;

  public loading = false;

  operationsForm: FormGroup;

  @Input() fetchedData : any = null;

  isCreateSub: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public taxUnitValuesService: TaxUnitValuesService,
    private alertsService: AlertsService,
    public dataHolderService: DataHolderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.operationsForm = this.formBuilder.group({
      id: ["-1", Validators.required],
      type_of_data: [this.type_of_data, Validators.required],
      sort_order: ["0", []],
      tax_unit_id: ["-1", Validators.required],
      table_column_id: [this.table_column_id, Validators.required],
    });

    if (!this.isCreate) {
      this.updateForm();
    }
  }

  onOperation(): any {

    this.alertsService.removeAlert();
    this.validate = true;
    this.loading = false;

    // console.log(this.operationsForm.invalid);

    if(this.operationsForm.value.tax_unit_id == '-1'){
      this.alertsService.addAlert("danger", "Please Select Tax Component");
      return;

    }

    if (this.operationsForm.invalid) {
      this.alertsService.addAlert("danger", "Invalid data");
      // console.log(this.operationsForm);

      return;
    }

    this.loading = true;
    const formData = this.operationsForm.value;


    if (this.isCreate) {
      this.alertsService.addAlert('info', 'Adding Data...');
      this.addOperation(formData);
      return;
    }
    else {
      this.alertsService.addAlert('info', 'Updating Data..');
      this.updateOperation(formData);
      return;
    }

  }

  addOperation(formData): void {

    this.taxUnitValuesService.addTaxUnitValue(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);


    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

  updateOperation(formData): void {


    this.taxUnitValuesService.updateTaxUnitValue(formData).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }

      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });

  }

  updateForm(response : any = null) {
    if (response != null) {
      this.alertsService.addAlert("success", response.message);
    }

    if(response){
      this.fetchedData = response['data'];

    }


    this.operationsForm.controls.id.setValue(this.fetchedData.id);
    this.operationsForm.controls.type_of_data.setValue(this.fetchedData.type_of_data);
    this.operationsForm.controls.sort_order.setValue(this.fetchedData.sort_order);
    this.operationsForm.controls.tax_unit_id.setValue(this.fetchedData.tax_unit_id);
    this.operationsForm.controls.table_column_id.setValue(this.fetchedData.table_column_id);



  }

  deleteOperation() {
    this.alertsService.addAlert('info', 'Removing Tax Unit Value..');

    this.taxUnitValuesService.deleteTaxUnitValue(this.fetchedData.id , this.fetchedData.type_of_data ).subscribe((response: any) => {
      this.alertsService.removeAlert();
      this.loading = false;
      if (!response.status) {
        this.alertsService.addAlert("danger", response.message);
        return;
      }
      this.alertsService.addAlert("success", response.message);
      this.validate = false;
      this.reloadData.emit(true);

      // this.myProfileService.fetchedAccountInfo = response["user"];



    },
      error => {
        this.loading = false;
        this.alertsService.addAlert("danger", "Server Error, Contact Admin");
        this.validate = false;
      });



  }

}
