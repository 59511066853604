<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>{{ isCreate ? 'ADD TAX INFORMATION' : 'UPDATE TAX INFORMATION' }}</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="operationsForm" (ngSubmit)="onOperation()" #form="ngForm" >

                        <div class="form-row mb-3">
                            <input name="type_of_data" class="form-control" type="hidden" formControlName="type_of_data"
                                readonly>

                            <input name="table_column_id" class="form-control" type="hidden"
                                formControlName="table_column_id" readonly>

                        </div>

                        <div *ngIf="!isCreate" class="form-row">

                            <div class="col-md-4 mb-3">
                                <label for="id">ID : </label>

                                <input name="id" class="form-control" id="id" type="text" placeholder="ID"
                                    formControlName="id" readonly>

                            </div>

                        </div>


                        <div class="form-row">


                            <div class="col-md-6 mb-3">
                                <label for="tax_unit_id">Tax Component : </label>
                             
                                <select class="form-control" placeholder="Tax Component .." formControlName="tax_unit_id"
                                    name="tax_unit_id" id="tax_unit_id">

                                    <option *ngFor="let key of Object.keys(dataHolderService.taxUnits)"
                                        [value]="dataHolderService.taxUnits[key].id">
                                        {{ dataHolderService.taxUnits[key].name }} : {{ dataHolderService.taxUnits[key].is_percentage == '1' ? dataHolderService.taxUnits[key].value + ' % ' :  dataHolderService.taxUnits[key].value | indianNumberFormat }}
                                    </option>
                                 
                                </select>

                                <app-refresh-fields-data></app-refresh-fields-data>


                                <div class="mt-1 mb-1">
                                    <a routerLink="/settings/tax-units/create"> Not in the List? Add Here </a>
                                    <br>
                                    <a routerLink="/settings/tax-units"> Manage Tax Units </a>
                                    <br>

                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="sort_order">Sort Order : </label>
                                <input class="form-control" id="sort_order" formControlName="sort_order"
                                    name="sort_order" />
                            </div>



                        

                        </div>

        
                        <button type="submit" class="btn btn-pill btn-air-primary btn-primary m-2"
                            [class.loader--text]="loading" [disabled]="loading" type="submit">
                            <span>{{ loading ? '' : ( isCreate ? ' ADD TAX' : 'UPDATE TAX' ) }}</span></button>

                        <button (click)="deleteOperation()" *ngIf="!isCreate"
                            class="btn btn-pill btn-secondary btn-air-secondary m-2" [class.loader--text]="loading"
                            [disabled]="loading"
                            type="button"><span>{{ loading ? '' : ' REMOVE TAX' }}</span></button>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>