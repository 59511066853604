<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">

                <div class="card-body">

                    <form [formGroup]="operationsForm" (ngSubmit)="onFilterRemainders()" id="formWithData">

                        <div class="form-row">
                            <div class="col-md-12">
                                <ng-container *ngIf="type_of_data == '2'">
                                    <h2>Sales / Projects - Remainders & Tasks</h2>
                                    <p>List of Tasks/ Remainders related to Sales </p>
                                </ng-container>
                                <ng-container *ngIf="type_of_data == '3'">
                                    <h2>Vendor Bookings / Purchases Remainders & Tasks</h2>
                                    <p>List of Tasks/ Remainders related to Vendor Bookings / Purchases</p>
                                </ng-container>


                                <input type="hidden" formControlName="page" name="page">
                            </div>
                        </div>

                        <div class="form-row mt-2 mb-4">



                            <div class="col-md-6">
                                <button type="button" (click)="isFormVisible = !isFormVisible"
                                    class="btn btn-info-gradien  m-1"> <i class="fa fa-filter"></i>
                                    {{ isFormVisible ? 'Hide ': 'Show' }} Filters </button>




                                <button *ngIf="isFormVisible" (click)="operationsForm.controls.page.setValue('1')"
                                    type="submit" class="btn btn-info-gradien  m-1" [class.loader--text]="loading"
                                    [disabled]="loading" type="submit">
                                    <i class="fa fa-check"></i>
                                    <span>{{ loading ? '' : ( ' Apply Filters'  ) }}</span></button>
                            </div>

                            <div class="col-md-3">

                                <!-- <label for="report_type">Select Report Type</label> -->

                                <select class="form-control" placeholder="Select Report Type.."
                                    formControlName="report_type" name="report_type" id="report_type">
                                    <option value="csv">CSV ( Excel )</option>
                                    <option value="pdf">PDF</option>
                                    <option value="html">View HTML</option>
                                    <!-- <option value="xlsx">Spreadsheet</option> -->


                                </select>


                            </div>

                            <div class="col-md-3">
                                <a type="button" (click)="dropDownsUpdate()" target="_blanc"
                                    [routerLink]="dataHolderService.apiBaseUrl + getDownloadUrl()" [queryParams]="{
                            id : operationsForm.value.id,
                            client_id_id : operationsForm.value.client_id_id,
                            client_id_name : operationsForm.value.client_id_name,

                            project_id_id : operationsForm.value.project_id_id,
                            project_id_name : operationsForm.value.project_id_name,

                            vendor_id_id : operationsForm.value.vendor_id_id,
                            vendor_id_name : operationsForm.value.vendor_id_name,

                            booking_id : operationsForm.value.booking_id,


                            title : operationsForm.value.title,
                            description : operationsForm.value.description,
                            status : operationsForm.value.status,

                            min_due_date : operationsForm.value.min_due_date,
                            max_due_date : operationsForm.value.max_due_date,

                            min_completed_date : operationsForm.value.min_completed_date,
                            max_completed_date : operationsForm.value.max_completed_date,

                          
                            pagination : operationsForm.value.pagination,
                            sort_by : operationsForm.value.sort_by,
                            sort_order : operationsForm.value.sort_order,
                            report_type :  operationsForm.value.report_type



                        }" class="btn btn-info-gradien m-1"> <i class="fa fa-download"></i> Export Records -
                                    {{ operationsForm.value.report_type |  uppercase  }}</a>
                            </div>

                        </div>

                        <div class="form-row">
                            <div class="col-sm-12 mb-2">

                                <p>
                                    <a class="m-1"> <small>Quick Links : </small></a>
                                    <a target="_blank" [routerLink]="['./']"
                                        [queryParams]="{ min_due_date : ('' | momentDates : 'week' : 'start'  )  , max_due_date :   ('' | momentDates : 'week' : 'end'  )}"
                                        class="badge badge-dark">Due : This Week</a>
                                    <a target="_blank" [routerLink]="['./']"
                                        [queryParams]="{ min_due_date : ('' | momentDates )  , max_due_date :  ('' | momentDates ) }"
                                        class="badge badge-dark">Due : Today</a>
                                    <a target="_blank" [routerLink]="['./']"
                                        [queryParams]="{ min_due_date : ('' | momentDates : 'month' : 'start'  )  , max_due_date :   ('' | momentDates : 'month' : 'end'  )}"
                                        class="badge badge-dark">Due : This Month</a>

                                    <a target="_blank" [routerLink]="['./']" [queryParams]="{ status : '3' }"
                                        class="badge badge-success">Status : Done</a>
                                    <a target="_blank" [routerLink]="['./']" [queryParams]="{ status : '1' }"
                                        class="badge badge-warning">Status : To Do</a>
                                    <a target="_blank" [routerLink]="['./']" [queryParams]="{ status : '2' }"
                                        class="badge badge-info">Status : In Progress</a>
                                </p>

                            </div>

                        </div>

                        <div class="form-row mt-2 mb-2">



                            <div class="col-md-4">
                                <label for="sort_by">Sort by</label>

                                <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                                    class="form-control" placeholder="Sort by.." formControlName="sort_by"
                                    name="sort_by" id="sort_by">
                                    <option value="id">ID</option>
                                    <option value="created_at">Created At</option>
                                    <option value="updated_at">Updated At</option>
                                    <option value="title">Title</option>
                                    <option value="due_date">Due Date</option>
                                    <option value="completed_date">Completed Date </option>





                                </select>
                            </div>


                            <div class="col-md-4">
                                <label for="sort_order">Sort Order</label>

                                <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                                    class="form-control" placeholder="Sort Order.." formControlName="sort_order"
                                    name="sort_order" id="sort_order">
                                    <option value="ASC">Low to High</option>
                                    <option value="DESC">High to Low</option>


                                </select>
                            </div>

                            <div class="col-md-4">
                                <label for="pagination">Results per page</label>

                                <select data-page-number="1" (change)="updatePageNumber($event); onFilterRemainders();"
                                    class="form-control" placeholder="Pagination.." formControlName="pagination"
                                    name="pagination" id="pagination">

                                    <option *ngFor="let l of dataHolderService.paginationOptions" [value]="l">
                                        {{ l }}
                                    </option>

                                </select>
                            </div>








                        </div>

                        <div [ngStyle]="{ 'display' : isFormVisible ? 'block' : 'none' }">

                            <div class="form-row mt-5">
                                <input type="hidden" formControlName="client_id_id" name="client_id_id">
                                <input type="hidden" formControlName="client_id_name" name="client_id_name">
                                <input type="hidden" formControlName="project_id_id" name="project_id_id">
                                <input type="hidden" formControlName="project_id_name" name="project_id_name">
                                <input type="hidden" formControlName="vendor_id_id" name="vendor_id_id">
                                <input type="hidden" formControlName="vendor_id_name" name="vendor_id_name">

                                <div class="col-md-6 mb-3">
                                    <label for="id">Remainder ID : </label>
                                    <input name="id" class="form-control" id="id" type="text"
                                        placeholder="Remainder ID " formControlName="id">
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="status">Status</label>
                                    <select class="form-control" id="status" type="text" placeholder="Status"
                                        formControlName="status" name="status">

                                        <option [value]="-1">All</option>
                                        <option [value]="1">To Do</option>
                                        <option [value]="2">In Progress </option>
                                        <option [value]="3">Done</option>




                                    </select>
                                    <div class="form-error-message"></div>
                                </div>



                            </div>

                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="title"> Title : </label>
                                    <input class="form-control" id="title" formControlName="title" name="title" />
                                    <div class="form-error-message">

                                    </div>

                                </div>


                                <div class="col-md-6 mb-3">
                                    <label for="description"> Description : </label>
                                    <textarea class="form-control" id="description" rows="3"
                                        formControlName="description" name="description"></textarea>
                                </div>
                            </div>

                            <!--
                            Project Sales Remainders Filters
                        -->
                            <ng-container *ngIf="type_of_data == '2'">

                                <div class="form-row">

                                    <div class="col-md-12 mb-3">
                                        <app-autocomplete-clients formControlName="client_id"
                                            [preSelectedValue]="preselectedClientValue"
                                            [hasPreSelectedValue]="hasPreSelectedClientValue">
                                        </app-autocomplete-clients>

                                        <div class="form-error-message">

                                        </div>

                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <app-autocomplete-projects formControlName="project_id"
                                            [preSelectedValue]="preselectedProjectValue"
                                            [hasPreSelectedValue]="hasPreSelectedProjectValue">
                                        </app-autocomplete-projects>

                                        <div class="form-error-message">

                                        </div>

                                    </div>

                                </div>

                            </ng-container>


                            <!--
                            Vendor Bookings Transactions Filters
                        -->
                            <ng-container *ngIf="type_of_data == '3'">

                                <div class="form-row">

                                    <div class="col-md-4 mb-3">
                                        <label for="booking_id">Purchase ID (PO No. ) : </label>
                                        <input name="booking_id" class="form-control" id="booking_id" type="text"
                                            placeholder="Purchase ID (PO No.)" formControlName="booking_id">
                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <app-autocomplete-vendors formControlName="vendor_id"
                                            [preSelectedValue]="preselectedVendorValue"
                                            [hasPreSelectedValue]="hasPreSelectedVendorValue">
                                        </app-autocomplete-vendors>

                                        <div class="form-error-message">

                                        </div>

                                    </div>

                                    <div class="col-md-12 mb-3">
                                        <app-autocomplete-projects formControlName="project_id"
                                            [preSelectedValue]="preselectedProjectValue"
                                            [hasPreSelectedValue]="hasPreSelectedProjectValue">
                                        </app-autocomplete-projects>

                                        <div class="form-error-message">

                                        </div>

                                    </div>

                                </div>

                            </ng-container>



                            <div class="form-row">



                                <div class="col-md-6 mb-3">
                                    <label for="min_due_date">Minimum Due Date</label>

                                    <input class="form-control" id="min_due_date" type="date"
                                        placeholder="Minimum Due Date" formControlName="min_due_date"
                                        name="min_due_date">

                                    <div class="form-error-message">

                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="max_due_date">Maximum Due Date </label>

                                    <input class="form-control" id="max_due_date" type="date"
                                        placeholder="Maximum Due Date" formControlName="max_due_date"
                                        name="max_due_date">

                                    <div class="form-error-message">

                                    </div>
                                </div>

                            </div>

                            <div class="form-row">



                                <div class="col-md-6 mb-3">
                                    <label for="min_completed_date">Minimum Completed Date</label>

                                    <input class="form-control" id="min_completed_date" type="date"
                                        placeholder="Minimum Completed Date" formControlName="min_completed_date"
                                        name="min_completed_date">

                                    <div class="form-error-message">

                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="max_completed_date">Maximum Completed Date </label>

                                    <input class="form-control" id="max_completed_date" type="date"
                                        placeholder="Maximum Completed Date" formControlName="max_completed_date"
                                        name="max_completed_date">

                                    <div class="form-error-message">

                                    </div>
                                </div>

                            </div>



                            <br><br>

                            <button (click)="operationsForm.controls.page.setValue('1')" type="submit"
                                class="btn btn-pill btn-air-primary btn-primary m-2" [class.loader--text]="loading"
                                [disabled]="loading" type="submit">
                                <span>{{ loading ? '' : ( ' APPLY FILTERS ' ) }}</span></button>

                        </div>




                    </form>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table card-table table-vcenter text-nowrap">

                                <thead>
                                    <tr>
                                        <th class="bg-primary" scope="col"></th>
                                        <th class="bg-primary" scope="col">
                                            Remainder ID <br>
                                            <small> Title (Notes) </small> <br>
                                            <small> Status </small>
                                        </th>

                                        <th class="bg-primary" scope="col">Due Date </th>
                                        <th class="bg-primary" scope="col">Completed On </th>

                                        <ng-container *ngIf="type_of_data == '2'">

                                            <th class="bg-primary" scope="col">Client</th>
                                            <th class="bg-primary" scope="col">Project</th>

                                        </ng-container>

                                        <ng-container *ngIf="type_of_data == '3'">

                                            <th class="bg-primary" scope="col">Vendor</th>
                                            <th class="bg-primary" scope="col">Purchase</th>
                                            <th class="bg-primary" scope="col">Project</th>


                                        </ng-container>



                                        <th class="bg-primary" scope="col">Created At </th>
                                        <th class="bg-primary" scope="col">Last Update </th>

                                    </tr>


                                </thead>

                                <tbody *ngIf="totalResults > 0">

                                    <tr *ngFor="let key of Object.keys(tabularData)">

                                        <td>

                                            <ng-container *ngIf="type_of_data == '2'">

                                                <a class=""
                                                    [routerLink]="[ '/projects/edit/' , tabularData[key].table_column_id ]">
                                                    <i class="fa fa-pencil-square-o"></i> Edit </a>


                                             </ng-container>
                                             
                                             
                                            <ng-container *ngIf="type_of_data == '3'">

                                                <a class=""
                                                    [routerLink]="[ '/vendor-bookings/edit/' , tabularData[key].table_column_id ]">
                                                    <i class="fa fa-pencil-square-o"></i> Edit </a>

                                            </ng-container>
                                        </td>

                                        <td>
                                            <b> {{ tabularData[key].id  }} </b> <br>

                                            <small> {{ tabularData[key].title | nullWithDefault   }} </small> <br>

                                            <small> <span class="badge badge-success"
                                                    *ngIf="tabularData[key].status == '3'">Done</span>

                                                <span class="badge badge-warning"
                                                    *ngIf="tabularData[key].status == '1'">To
                                                    Do</span>
                                                <span class="badge badge-info"
                                                    *ngIf="tabularData[key].status  == '2'">In
                                                    Progress</span> </small> <br>

                                        </td>


                                        <td>

                                            <p>{{ tabularData[key].due_date ? ( tabularData[key].due_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}
                                            </p>

                                            <small>
                                                {{  tabularData[key].due_date ? (tabularData[key].due_date | momentDateDiffPipe ): ''  }}
                                            </small>
                                        </td>

                                        <td>

                                            <p>{{ tabularData[key].completed_date ? ( tabularData[key].completed_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}
                                            </p>

                                            <small>
                                                {{  tabularData[key].completed_date ? (tabularData[key].completed_date | momentDateDiffPipe ): ''  }}
                                            </small>
                                        </td>


                                        <ng-container *ngIf="type_of_data == '2'">

                                            <td>
                                                <small>

                                                    {{ tabularData[key].project.client.name }}<br>
                                                    Client ID : {{ tabularData[key].project.client.id }}<br>
                                                    {{ tabularData[key].project.client.email | nullWithDefault }}<br>
                                                    {{ tabularData[key].project.client.mobile_number | nullWithDefault }}
                                                    <br>
                                                </small>


                                            </td>

                                            <td>

                                                <small>

                                                    {{ tabularData[key].project.name }} <br>
                                                    Project ID : {{ tabularData[key].project.id }} <br>

                                                    Project Status :
                                                    <span class="badge badge-success"
                                                        *ngIf="tabularData[key].project.status == '2'">Completed</span>
                                                    <span class="badge badge-dark"
                                                        *ngIf="tabularData[key].project.status == '0'">On
                                                        Going</span>
                                                    <span class="badge badge-primary"
                                                        *ngIf="tabularData[key].project.status== '1'">Upcoming</span>
                                                    <span class="badge badge-warning"
                                                        *ngIf="tabularData[key].project.status == '4'">Planning /
                                                        Estimation</span>
                                                    <span class="badge badge-info"
                                                        *ngIf="tabularData[key].project.status  == '5'">Quotation</span>

                                                    <br>


                                                    Order Price :
                                                    {{ ( tabularData[key].project.calculated_total_order_price ) | indianNumberFormat }}
                                                    <br>
                                                    Payment Received :
                                                    {{  tabularData[key].project.calculated_amount_paid_till_now  | indianNumberFormat }}
                                                    <br>
                                                    Payment Pending :
                                                    {{ ( (tabularData[key].project.calculated_total_order_price) - tabularData[key].project.calculated_amount_paid_till_now  ) | indianNumberFormat }}
                                                    <br>

                                                </small>

                                            </td>

                                        </ng-container>

                                        <ng-container *ngIf="type_of_data == '3'">

                                            <td>

                                                <small>

                                                    {{ tabularData[key].vendor_booking.vendor.name }} <br>
                                                    Vendor ID : {{ tabularData[key].vendor_booking.vendor.id }} <br>
                                                    {{ tabularData[key].vendor_booking.vendor.email | nullWithDefault }}
                                                    <br>
                                                    {{ tabularData[key].vendor_booking.vendor.mobile_number | nullWithDefault }}
                                                    <br>

                                                </small>

                                            </td>

                                            <td>

                                                <small>
                                                    {{ tabularData[key].vendor_booking.name | nullWithDefault }} <br>
                                                    Purchase ID (PO No.) : {{ tabularData[key].vendor_booking.id }} <br>

                                                    Booking Status :
                                                    <span class="badge badge-success"
                                                        *ngIf="tabularData[key].vendor_booking.status == '2'">Completed</span>
                                                    <span class="badge badge-dark"
                                                        *ngIf="tabularData[key].vendor_booking.status == '0'">On
                                                        Going</span>
                                                    <span class="badge badge-primary"
                                                        *ngIf="tabularData[key].vendor_booking.status== '1'">Upcoming</span>
                                                    <span class="badge badge-warning"
                                                        *ngIf="tabularData[key].vendor_booking.status == '4'">Planning /
                                                        Estimation</span>
                                                    <span class="badge badge-info"
                                                        *ngIf="tabularData[key].vendor_booking.status  == '5'">Quotation</span>
                                                    <br>




                                                    Order Price :
                                                    {{ ( tabularData[key].vendor_booking.calculated_total_order_price  ) | indianNumberFormat }}
                                                    <br>
                                                    Payment Done :
                                                    {{  tabularData[key].vendor_booking.calculated_amount_paid_till_now  | indianNumberFormat }}
                                                    <br>

                                                    <ng-container
                                                        *ngIf="( (tabularData[key].vendor_booking.calculated_total_order_price) == tabularData[key].vendor_booking.calculated_amount_paid_till_now  )">

                                                        <span style="color: green !important;">FULL PAYMENT DONE</span>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="( (tabularData[key].vendor_booking.calculated_total_order_price) != tabularData[key].vendor_booking.calculated_amount_paid_till_now  )">

                                                        <span style="color: red !important;">PENDING PAYMENT <br>
                                                            {{ ( tabularData[key].vendor_booking.calculated_total_order_price - tabularData[key].vendor_booking.calculated_amount_paid_till_now ) | indianNumberFormat }}
                                                        </span>
                                                    </ng-container>

                                                </small>


                                            </td>

                                            <td>
                                                <ng-container *ngIf="tabularData[key].vendor_booking.project_id">

                                                    <small>
                                                        <b>Project : </b> <br />

                                                        {{ tabularData[key].vendor_booking.project.name }} <br />
                                                        Project ID :
                                                        {{ tabularData[key].vendor_booking.project.id }}<br />

                                                        <br />

                                                        <b>Client : </b> <br />
                                                        {{ tabularData[key].vendor_booking.project.client.name }}<br />
                                                        Client ID :
                                                        {{ tabularData[key].vendor_booking.project.client.id }}<br />

                                                        <br />


                                                    </small>


                                                </ng-container>
                                            </td>

                                        </ng-container>

                                        <td>
                                            <p>{{ tabularData[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                            </p>
                                            <p> <small> {{ tabularData[key].created_at | momentDateDiffPipe  }} (
                                                    {{ tabularData[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                    ) </small> </p>
                                        </td>

                                        <td>
                                            <p>{{ tabularData[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                            </p>
                                            <p> <small> {{ tabularData[key].updated_at | momentDateDiffPipe  }} (
                                                    {{ tabularData[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                    ) </small> </p>
                                        </td>





                                    </tr>

                                </tbody>

                            </table>
                        </div>

                    </div>

                    <div class="mt-2">
                        <div class="d-flex justify-content-center ">

                            <div *ngIf="totalResults < 1" class="">
                                <p class="d-flex justify-content-center text-danger"> <b>No results found, try modifying
                                        your query </b></p>
                            </div>

                            <div *ngIf="totalResults > 0" class="">
                                <p class="d-flex justify-content-center"> <b>Total Results : {{ totalResults }} </b></p>


                                <a class="btn badge badge-pagination " *ngFor="let paginationLink of paginationLinks "
                                    [ngClass]="  paginationLink == operationsForm.value.page ? 'badge-dark' : 'badge-light' "
                                    [routerLink]="['./']" [queryParams]="{
                                        id : operationsForm.value.id,
                                        client_id_id : operationsForm.value.client_id_id,
                                        client_id_name : operationsForm.value.client_id_name,
            
                                        project_id_id : operationsForm.value.project_id_id,
                                        project_id_name : operationsForm.value.project_id_name,

                                        vendor_id_id : operationsForm.value.vendor_id_id,
                                        vendor_id_name : operationsForm.value.vendor_id_name,

                                        booking_id : operationsForm.value.booking_id,

            
                                        title : operationsForm.value.title,
                                        description : operationsForm.value.description,
                                        status : operationsForm.value.status,
            
                                        min_due_date : operationsForm.value.min_due_date,
                                        max_due_date : operationsForm.value.max_due_date,
            
                                        min_completed_date : operationsForm.value.min_completed_date,
                                        max_completed_date : operationsForm.value.max_completed_date,
            
                                        page : paginationLink == '...' ? '1' : paginationLink,
                                        pagination : operationsForm.value.pagination,
                                        sort_by : operationsForm.value.sort_by,
                                        sort_order : operationsForm.value.sort_order,
                                        report_type :  operationsForm.value.report_type
                               
                            }">
                                    {{ paginationLink }}
                                </a>

                                <p class="d-flex justify-content-center mt-3"> <b> Page :
                                        {{ operationsForm.value.page }} </b></p>

                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>