<ng-container>


    <div class="card display-container-card">
        <div class="card-header card-header-bg display-container-card-bg">
            <p class="card-header-heading"> Manage Booked Items of this Order </p>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap">
                    <!-- <table class="table table-striped table-responsive mt-5 table card-table table-vcenter text-nowrap"> -->
                    <thead>
                        <tr>
                            <th class="" scope="col">
                                <a (click)="isCreate = true; addEditFormVisible = true;" class="btn btn-primary btn-sm">
                                    <i class="fa fa-plus"></i> Add Item </a>
                            </th>

                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>
                            <th class="" scope="col"></th>




                            <!-- <th class="" scope="col"> </th> -->
                            <th class="reloadBookedItems" (click)="refreshOnlyChildData()" scope="col">
                                <a class="link-dark"> <i class="fa fa-rotate-right"></i> Refresh </a>
                            </th>
                            <!-- <th class="" scope="col"> </th> -->

                        </tr>

                        <tr>
                            <th class="bg-primary" scope="col"></th>
                            <th class="bg-primary" scope="col">
                                Record ID <br>
                                <small>Item Booked Date : </small>
                            </th>
                            <th class="bg-primary" scope="col">

                                <small>
                                    Item <br>
                                    Quantity<br>
                                    Unit of measurement <br>
                                </small>
                            </th>

                            <th class="bg-primary" scope="col">
                                <small>
                                   Taxes Applicable <br>
                                   Tax (of all units ) 
                                </small>
                            </th>

                            
                            <th class="bg-primary" scope="col"> Price <br> <small>(of all units, with out tax)
                            </small></th>


                            <th class="bg-primary" scope="col">Total Price <br> <small>(of all units, including taxes)
                                </small></th>

                            <th class="bg-primary" scope="col">

                                <small>
                                    Price Per Unit ( With out tax) <br>
                                    Total Tax Per Unit ( With out tax) <br>
                                    Total Price Per Unit ( including tax) <br>
                                </small>
                            </th>

                            <th class="bg-primary" scope="col">Timestamps</th>





                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="count < 1">
                            <td>No Records Found</td>
                        </tr>

                        <ng-container *ngIf="fetchedAllData && count > 0">



                            <tr *ngFor="let key of Object.keys(fetchedAllData)">
                                <td>
                                    <button (click)="fetchValueAndDisplayForm(fetchedAllData[key].id)"
                                        class="btn btn-secondary btn-sm">
                                        <i class="fa fa-pencil-square-o"></i> Edit </button>

                                </td>
                                <td>
                                    {{ fetchedAllData[key].id }} <br>

                                    <small>

                                        {{ fetchedAllData[key].item_order_date ? ( fetchedAllData[key].item_order_date | momentDateTransformPipe : ('Do MMM, YYYY') ): ''   }}


                                    </small>


                                </td>
                                <td> <small>
                                        {{ fetchedAllData[key].available_booking_item.name    }} <br>

                                        {{ fetchedAllData[key].quantity }}

                                        <br>

                                        {{  fetchedAllData[key].unit.fullform + ' ( ' + fetchedAllData[key].unit.symbol  + ' )' }}

                                    </small>

                                </td>


                                <td>
                                    <small> 
                                        {{ fetchedAllData[key].taxes_applicable == '1' ? 'Yes' : 'No'  }}  <br>
                                        {{ fetchedAllData[key].calculated_total_tax | indianNumberFormat  }}
                                    </small>
                                </td>

                                <td> <small> {{ fetchedAllData[key].price | indianNumberFormat  }} </small></td>


                                <td> <small> {{ fetchedAllData[key].calculated_total_item_price | indianNumberFormat  }} </small></td>

                                <td>

                                    <small>
                                        {{  ( fetchedAllData[key].price / fetchedAllData[key].quantity ) | indianNumberFormat  }}
                                        <br>
                                        {{  ( fetchedAllData[key].calculated_total_tax / fetchedAllData[key].quantity ) | indianNumberFormat  }}
                                        <br>
                                        {{  ( fetchedAllData[key].calculated_total_item_price / fetchedAllData[key].quantity ) | indianNumberFormat  }}
                                        <br>

                                    </small>

                                </td>

                                <td>

                                    <p>
                                        <small>
                                            Created At :
                                            <!-- <small>{{ fetchedAllData[key].created_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->

                                            <small> {{ fetchedAllData[key].created_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].created_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>

                                    <p>
                                        <small>

                                            Updated At :

                                            <!-- <small>{{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('YYYY-MM-DD HH:mm:ss ')  }}
                                        </small> -->
                                            <small> {{ fetchedAllData[key].updated_at | momentDateDiffPipe  }}
                                                (
                                                {{ fetchedAllData[key].updated_at | momentDateTransformPipe : ('hh:mm a MMM Do, YYYY ')  }}
                                                ) </small>

                                        </small>
                                    </p>
                                </td>

                            </tr>





                        </ng-container>









                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <section class="fullScreenPopup" *ngIf="addEditFormVisible">
        <div class=" text-right mt-1 mb-1" (click)="addEditFormVisible = false; reloadData(); ">
            <button class="btn btn-primary"> <i class="fa fa-arrow-circle-o-left mr-2"></i> CANCEL</button>
        </div>


        <app-booked-items-add-edit [isCreate]="isCreate" [type_of_data]="type_of_data"
            [table_column_id]="table_column_id" [fetchedData]="fetchedValue" (reloadData)="reloadData($event)">
        </app-booked-items-add-edit>




    </section>



</ng-container>